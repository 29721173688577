import styled from "styled-components"

import clearIcon from "../../assets/icons/icon-clear.svg"
import { ImageButton } from "../../styles/button"

export const Search = styled.div.attrs(props => ({
  width: props.width ? props.width : "auto",
  height: props.height ? props.height : "auto",
  flex: props.shouldFlex ? "1" : "0 0 auto",
  marginTop: props.marginTop ? props.marginTop : 0,
  marginRight: props.marginRight ? props.marginRight : 0,
  marginBottom: props.marginBottom ? props.marginBottom : 0,
  marginLeft: props.marginLeft ? props.marginLeft : 0,
  border: props.border ? props.border : "none"
}))`
  display: flex;
  align-items: center;

  flex: ${props => props.flex};

  width: ${props => props.width};
  height: ${props => props.height};

  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};

  padding: 2px 10px 2px 55px;

  background-color: #fff;
  background-image: ${props => `url(${props.theme.faq.icons.searchIcon})`};
  background-repeat: no-repeat;
  background-position: 16px 50%;
  background-size: 28px;

  border: ${props => props.border};
  border-radius: 3px;

  input[type="text"] {
    width: calc(100% - 28px);

    border: none;

    font-size: 1.2em;
  }
`

export const ClearButton = styled(ImageButton).attrs(() => ({
  src: clearIcon
}))`
  width: 28px;
  height: 28px;

  background-size: 28px;
`
