import styled from "styled-components"

export const ListArticles = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 28px;

  margin-bottom: 60px;

  @media (max-width: 976px) {
    grid-template-columns: 100%;
  }
`
