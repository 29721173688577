import PropTypes from "prop-types"
import React from "react"

import BrandItem from "../../components/BrandItem"
import Title from "../../components/Title"
import { useScrollTo } from "../../helpers"
import PageTemplate from "../PageTemplate"
import Registration from "../Registration"
import * as S from "./style"

const RegistrationOptionsPage = ({ brands, translations, registrationOptions }) => {
  useScrollTo()

  const List = brands.map(({ brandLogo, slug }) => (
    <BrandItem key={slug} imageSrc={brandLogo} routePath={`/${translations.register.toLowerCase()}/${slug}`} />
  ))
  return (
    <>
      {registrationOptions.length > 1 ? (
        <PageTemplate>
          <S.BrandContainer>
            <Title>{translations.register}</Title>
            <S.BrandInner>{List}</S.BrandInner>
          </S.BrandContainer>
        </PageTemplate>
      ) : (
        <Registration translations={translations} url={registrationOptions[0].url} />
      )}
    </>
  )
}

RegistrationOptionsPage.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.any),
  translations: PropTypes.objectOf(PropTypes.any),
  registrationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
}

RegistrationOptionsPage.defaultProps = {
  brands: {},
  translations: {}
}

export default RegistrationOptionsPage
