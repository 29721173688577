import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 240px;
  margin: 60px 0 20px;
  background: ${props => props.theme.news.previewInfoBg};
`
export const HeaderContent = styled.div`
  ${constrictedContainer};
  height: 100%;
  background: url(${props => props.theme.testimonials.headerBgImage}) no-repeat right;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const HeaderSubTitle = styled.p`
  color: ${props => props.theme.testimonials.headerTextColour};
  font-size: 1.1em;
  @media (max-width: 846px) {
    width: 100%;
    text-align: center;
  }
`

export const TestimonialsContainer = styled.div`
  ${constrictedContainer};
  margin-bottom: 60px;
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
  }
`
