import styled from "styled-components"

export const RegisterContainer = styled.div`
  background-color: ${props => props.theme.footer.bgColor};

  padding: 55px 0;

  button {
    margin: 0 auto;
  }
`
