import Select from "react-select"
import styled from "styled-components"

export const Dropdown = styled.div`
  width: 49%;

  select {
    width: 100%;
    height: 48px;

    border: 1px solid #8b8b8b;
    border-radius: 3px;

    font-size: 1.2em;
    color: #8f8f8f;
    text-indent: 4px;
  }
`

export const ReactSelect = styled(Select)`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 1.2em;

  .react-select__control {
    width: 100%;
    height: 48px;

    border: 1px solid #8b8b8b;
    border-radius: 3px;

    text-indent: 4px;
  }
`

export const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    display: inline-block;

    margin-bottom: 4px;

    font-family: ${props => props.theme.fonts.primary};
    font-weight: 700;
    font-size: 1.1em;
    color: #8f8f8f;
  }

  button {
    display: inline-block;

    padding: 0;

    background-color: transparent;
    border: none;

    font-size: 1.1em;

    span {
      font-size: 1;
      font-weight: 600;
      color: ${props => props.theme.colours.main};

      cursor: pointer;

      :hover {
        text-decoration: underline;
      }
    }
  }
`
