import PropTypes from "prop-types"
import React from "react"
import { useHistory, useLocation } from "react-router-dom"

import Dropdown from "../../components/Dropdown"
import NewsGrid from "../../components/NewsGrid"
import NewsList from "../../components/NewsList"
import PaginationControl from "../../components/PaginationControl"
import Search from "../../components/Search"
import Title from "../../components/Title"
import { useScrollTo } from "../../helpers"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const listArticlesPerPage = 13
const gridArticlesPerPage = 13
const defaultFilter = {
  value: "all",
  label: "All"
}

const NewsPage = ({ news, categories, translations }) => {
  useScrollTo()
  const getType = type =>
    categories
      .reduce((sum, { categoryName, categoryType }) => (categoryType === type ? sum.concat(categoryName) : sum), [])
      .map(filter => ({ value: filter.toLowerCase(), label: filter }))

  const brandFilters = {
    title: "Brand",
    filters: getType("brands")
  }

  const otherFilters = {
    title: "Other",
    filters: getType("other")
  }

  const title = (isSearchActive, searchText, isFilterActive, numOfArticles) => {
    let component

    if (!isSearchActive && !isFilterActive) {
      component = "More"
    } else if (isSearchActive) {
      component = (
        <p>
          Showing results for:
          <span>
            &nbsp;
            {searchText.length > 30 ? `"${searchText.substr(0, 30)}..."` : `"${searchText}"`}
          </span>
        </p>
      )
    } else {
      component = <p>Results</p>
    }

    return <S.ListTitle isShown={numOfArticles > 0}>{component}</S.ListTitle>
  }

  const createPages = (defaultArticles, searchText, isSearchActive, brandFilter, otherFilter, currentPage, articlesPerPage) => {
    let articles = defaultArticles
    const searchQuery = searchText.toLowerCase()

    if (isSearchActive) {
      articles = articles.filter(
        article => article.newsTitle.toLowerCase().indexOf(searchQuery) > -1 || article.newsText.toLowerCase().indexOf(searchQuery) > -1
      )
    }

    if (brandFilter !== defaultFilter) {
      articles = articles.filter(
        article => article.category.length > 0 && article.category.some(item => item.categoryName.toLowerCase() === brandFilter.value)
      )
    }

    if (otherFilter !== defaultFilter) {
      articles = articles.filter(
        article => article.category.length > 0 && article.category.some(item => item.categoryName.toLowerCase() === otherFilter.value)
      )
    }

    const articlesForThisPage = currentPage * articlesPerPage

    return {
      articles: articles.slice(articlesForThisPage, articlesForThisPage + articlesPerPage),
      totalArticles: articles.length
    }
  }

  const paginationReducer = (state, action) => {
    switch (action.type) {
      case "setCurrentPage":
        return {
          ...state,
          currentPage: action.payload.currentPage
        }
      case "setTotalArticles":
        return {
          ...state,
          totalArticles: action.payload.totalArticles
        }
      default:
        return state
    }
  }

  const numberOfGridTiles = _pageWidth => {
    if (_pageWidth > 1280) return 5
    if (_pageWidth > 976 && _pageWidth <= 1280) return 3

    return 1
  }

  const updateQueryString = (history, searchText, brandFilter, otherFilter) => {
    const parameters = []

    if (searchText !== "") {
      parameters.push(`search=${searchText}`)
    }

    if (brandFilter.value !== defaultFilter.value) {
      parameters.push(`brand=${brandFilter.value}`)
    }

    if (otherFilter.value !== defaultFilter.value) {
      parameters.push(`type=${otherFilter.value}`)
    }

    history.replace(`?${parameters.join("&")}`)
  }
  const params = new URLSearchParams(useLocation().search)

  const [defaultArticles] = React.useState(news.filter(x => !x.hideFromNewsFeed))

  const [searchText, setSearchText] = React.useState(params.get("search") || "")

  const [filtersShown, setFiltersShown] = React.useState(false)

  const getFilterFromParam = type => {
    const param = params.get(type)

    if (!param) return defaultFilter

    return { value: param.toLowerCase(), label: param }
  }

  const [brandFilter, setBrandFilter] = React.useState(getFilterFromParam("brand"))
  const [otherFilter, setOtherFilter] = React.useState(getFilterFromParam("type"))

  const [pageWidth, setPageWidth] = React.useState(window.innerWidth)

  const [paginationState, paginationDispatch] = React.useReducer(paginationReducer, {
    currentPage: 0,
    totalArticles: defaultArticles.length
  })

  const isSearchActive = searchText !== ""

  const isBrandFilterActive = brandFilter !== defaultFilter
  const isOtherFilterActive = otherFilter !== defaultFilter
  const isFilterActive = isBrandFilterActive || isOtherFilterActive

  const isFirstPage = paginationState.currentPage === 0
  const isInitialPageState = !isSearchActive && !isFilterActive && isFirstPage

  const articlesPerPage = isInitialPageState ? gridArticlesPerPage : listArticlesPerPage

  const getActiveFilterCount = () => {
    let count = 0

    if (isBrandFilterActive) count += 1
    if (isOtherFilterActive) count += 1

    return count
  }

  const history = useHistory()

  const handleSearchChange = e => {
    setSearchText(e.target.value)

    updateQueryString(history, e.target.value, brandFilter, otherFilter)
  }

  const handleSearchClear = () => {
    setSearchText("")

    updateQueryString(history, "", brandFilter, otherFilter)
  }

  const handleBrandFilterChange = selectedFilter => {
    setBrandFilter(selectedFilter)

    updateQueryString(history, searchText, selectedFilter, otherFilter)
  }

  const handleOtherFilterChange = selectedFilter => {
    setOtherFilter(selectedFilter)

    updateQueryString(history, searchText, brandFilter, selectedFilter)
  }

  const handleClearAll = () => {
    setSearchText("")
    setBrandFilter(defaultFilter)
    setOtherFilter(defaultFilter)

    updateQueryString(history, "", defaultFilter, defaultFilter)
  }

  const handlePageChange = e => {
    paginationDispatch({
      type: "setCurrentPage",
      payload: { currentPage: e.selected }
    })

    window.scrollTo(0, 0)
  }

  React.useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth)

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const pages = createPages(defaultArticles, searchText, isSearchActive, brandFilter, otherFilter, paginationState.currentPage, articlesPerPage)

  React.useEffect(() => {
    paginationDispatch({
      type: "setTotalArticles",
      payload: { totalArticles: pages.totalArticles }
    })
  }, [pages.totalArticles])

  const { articles } = pages

  const numOfGridTiles = numberOfGridTiles(pageWidth)

  return (
    <PageTemplate>
      <S.News id="news">
        <Title>{translations.news}</Title>
        <S.Options>
          <Search
            text={searchText}
            onChange={handleSearchChange}
            onClear={handleSearchClear}
            shouldFlex
            height="42px"
            marginRight="15px"
            border="1px solid #8b8b8b"
            translations={translations}
          />
          <S.FilterButton isOpen={filtersShown} numOfActiveFilters={getActiveFilterCount()} onClick={() => setFiltersShown(!filtersShown)}>
            <p>{getActiveFilterCount()}</p>
            <span>{translations.newsFilters}</span>
          </S.FilterButton>
        </S.Options>

        <S.FilterBar isShown={filtersShown}>
          <Dropdown
            className="filter-dropdown"
            isMulti={false}
            title="Brands"
            options={brandFilters.filters}
            value={brandFilter}
            onChange={handleBrandFilterChange}
            onClear={() => handleBrandFilterChange(defaultFilter)}
          />

          <Dropdown
            className="filter-dropdown"
            isMulti={false}
            title="Other"
            options={otherFilters.filters}
            value={otherFilter}
            onChange={handleOtherFilterChange}
            onClear={() => handleOtherFilterChange(defaultFilter)}
          />
        </S.FilterBar>
        {articles.length > 0 && !isSearchActive && !isBrandFilterActive && !isOtherFilterActive && <S.Title>{translations.all}</S.Title>}

        {articles.length > 0 && isInitialPageState && <NewsGrid articles={articles.slice(0, numOfGridTiles)} translations={translations} />}

        {paginationState.currentPage === 0 && title(isSearchActive, searchText, isFilterActive, articles.length)}

        {articles.length > 0 && (
          <NewsList articles={isInitialPageState ? articles.slice(numOfGridTiles) : articles} pageWidth={pageWidth} translations={translations} />
        )}

        {(isSearchActive || isBrandFilterActive || isOtherFilterActive) && articles.length === 0 && (
          <S.NoResultsFound>
            <h3>{translations.noArticlesFound}</h3>
            <S.ClearResultsButton onClick={handleClearAll}>{translations.clear}</S.ClearResultsButton>
          </S.NoResultsFound>
        )}

        {Math.ceil(paginationState.totalArticles / articlesPerPage) > 1 && (
          <PaginationControl
            totalPages={Math.ceil(paginationState.totalArticles / articlesPerPage)}
            currentPage={paginationState.currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </S.News>
    </PageTemplate>
  )
}

NewsPage.propTypes = {
  news: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  translations: PropTypes.objectOf(PropTypes.any)
}

NewsPage.defaultProps = {
  translations: {}
}

export default NewsPage
