export const SMALL = "small"
export const MEDIUM = "medium"
export const LARGE = "large"
export const NAME = "name"
export const SURNAME = "surname"
export const EMAIL = "email"
export const MESSAGE = "message"
export const ACCEPT = "accept"
export const CONTACT = "contact"
export const TEXT = "text"
export const BOT_FIELD = "bot-field"
export const POST = "POST"
export const SUBMIT = "submit"
export const SUCCESS = "success"
export const ERROR = "error"
export const PREVIEW = "preview"
export const HOME_PAGE = "homepage"
export const SLIDE = "slide"
export const HERO = "hero"
export const MAIN = "main"
export const NEWS = "news"
export const BRAND = "brand"
export const MARKET = "market"
export const FAQ = "faq"
export const NEWSCATEGORY = "newsCategory"
export const TESTIMONIALS = "testimonials"
export const TRANSLATIONS = "translations"
export const PAGES = "pages"
