import styled, { css } from "styled-components"

import { CtaButton } from "../../styles/button"
import { constrictedContainer } from "../../styles/globalStyle"

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 400px;

  margin: 60px 0 20px;
  padding-top: 60px;

  background: ${props => props.theme.news.previewInfoBg};

  @media (max-width: ${props => props.theme.sizes.faq.large}px) {
    height: 330px;
  }

  @media (max-width: ${props => props.theme.sizes.faq.medium}px) {
    height: 290px;
  }

  @media (max-width: ${props => props.theme.sizes.faq.small}px) {
    height: 260px;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  width: 60%;
`

const filterBoxShadow = "0px 2px 15px rgba(0, 0, 0, 0.35);"
const filterBoxSubtleShadow = "0px 2px 15px rgba(0, 0, 0, 0.15);"
const filterTransform = "scale(1.05)"

export const Filter = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  flex: 0 0 auto;

  width: 250px;
  height: 250px;

  padding-bottom: 20px;

  background-color: #fff;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 30%;

  border: none;
  border-radius: 8px;
  box-shadow: ${filterBoxSubtleShadow};

  font-size: 1.1125em;

  transition: transform 0.15s ease-in-out;

  cursor: pointer;

  :not(:last-child) {
    margin-right: 20px;
  }

  h4 {
    font-weight: bold;
  }

  ${props =>
    props.$isSelected &&
    css`
      box-shadow: ${filterBoxShadow};

      transform: scale(1.1);
      transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    `}

  :hover {
    ${props =>
      !props.$isSelected &&
      css`
        box-shadow: ${filterBoxShadow};
        transform: ${filterTransform};
        transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      `}
  }

  @media (max-width: ${props => props.theme.sizes.faq.large}px) {
    width: 200px;
    height: 200px;

    p {
      display: none;
    }

    ${Header} {
      height: 300px;
    }
  }

  @media (max-width: 746px) {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 646px) {
    width: 100px;
    height: 100px;

    padding-bottom: 5px;

    h4 {
      /* display: none; */
      font-size: 0.7em;
    }
  }
`

export const ActiveSearchText = styled.p`
  margin: 0 auto 20px;

  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.1em;
  font-style: italic;
`

export const NoResultsFound = styled.div`
  margin: auto;

  h3 {
    font-size: 1.5em;
    font-family: ${props => props.theme.fonts.primary};
    text-align: center;

    margin-bottom: 30px;
  }
`

export const ClearResultsButton = styled(CtaButton)`
  margin: auto;
`

export const FaqContainer = styled.div`
  ${constrictedContainer};

  display: flex;
  flex-direction: column;
  flex: 1;

  margin-bottom: 60px;
`
