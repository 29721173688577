import * as PropTypes from "prop-types"
import React from "react"

import Title from "../../components/Title"
import { useScrollTo } from "../../helpers"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const Registration = ({ translations, url }) => {
  useScrollTo()

  return (
    <PageTemplate>
      <S.Registration>
        <Title>{translations.affiliatesRegistration}</Title>
        <S.Iframe src={url} />
      </S.Registration>
    </PageTemplate>
  )
}

Registration.propTypes = {
  translations: PropTypes.objectOf(PropTypes.any),
  url: PropTypes.string
}
Registration.defaultProps = {
  translations: {},
  url: null
}

export default Registration
