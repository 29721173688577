import ReactMarkdown from "react-markdown"
import styled, { css } from "styled-components"

import { fadeInOut } from "../../styles/animation"

export const Contact = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  background-color: ${props => props.bgColor};

  h3 {
    margin-bottom: 48px;

    font-size: 1.875em;
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 700;
  }
`

export const Form = styled.form`
  max-width: 723px;

  ${props =>
    props.isHidden &&
    css`
      visibility: hidden;
    `}
`

const errorColour = "#e61a1a"

const inputSharedStyling = css`
  box-sizing: border-box;

  margin-bottom: 40px;

  background-color: transparent;

  border: none;
  border-bottom: 2px solid #8b8b8b;

  color: #000;

  font-size: 1.125em;
  font-style: italic;

  ::placeholder {
    color: #000;
    text-transform: capitalize;
  }

  :focus {
    outline: none;
    border-color: #000;

    color: ${props => props.theme.colours.black};
  }

  ${props =>
    props.error &&
    css`
      border-color: ${errorColour};

      color: ${errorColour};

      ::placeholder {
        color: ${errorColour};
      }

      :focus {
        border-color: ${errorColour};

        color: ${errorColour};
      }
    `}
`

export const TextInput = styled.input.attrs({
  type: "text"
})`
  ${inputSharedStyling};

  display: inline-block;

  width: 45%;
  height: 40px;

  :first-child {
    margin-right: 10%;
  }

  @media (max-width: 550px) {
    display: block;

    width: 100%;
  }
`

export const EmailInput = styled.input.attrs({
  type: "email"
})`
  ${inputSharedStyling};

  width: 100%;
  height: 40px;
`

export const HiddenInput = styled.input`
  display: none;
`

export const TextArea = styled.textarea`
  ${inputSharedStyling};

  min-width: 100%;
  min-height: 60px;
  max-height: 200px;

  resize: vertical;
`
export const CheckBoxContainer = styled.div`
  margin-bottom: 20px;

  span {
    font-family: ${props => props.theme.fonts.primary};
  }
`
export const CheckBox = styled.input.attrs(() => ({
  type: "checkbox"
}))``

export const AcceptTermsLink = styled(ReactMarkdown)`
  display: inline-block;
`

export const Alert = styled.div`
  position: absolute;
  top: 40px;

  padding: 0.5rem 1.25rem;

  border: 1px solid transparent;
  border-radius: 0.25rem;

  opacity: 0;

  animation: 1s ${fadeInOut} ease-out 0.5s forwards, 1s ${fadeInOut} ease-out 2.5s forwards reverse;

  ${props =>
    props.type === "success" &&
    css`
      background-color: #d4edda;

      border-color: #c3e6cb;

      color: #155724;
    `}

  ${props =>
    props.type === "error" &&
    css`
      background-color: #f8d7da;

      border-color: #f5c6cb;

      color: #721c24;
    `}
`
