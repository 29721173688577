import styled, { css } from "styled-components"

import circleIcon from "../../assets/icons/icon-circle.svg"
import filterIcon from "../../assets/icons/icon-filter.svg"
import { CtaButton } from "../../styles/button"
import { constrictedContainer } from "../../styles/globalStyle"

export const News = styled.div`
  ${constrictedContainer};

  display: flex;
  flex-direction: column;
  flex: 1;

  margin-top: 60px;
  padding-top: 60px;
`

export const Title = styled.h2`
  position: relative;

  margin-bottom: 20px;

  font-family: ${props => props.theme.fonts.primary};
  font-weight: bold;
  font-size: 1.615em;
  text-transform: uppercase;
`

export const ListTitle = styled(Title)`
  margin-bottom: 40px;

  ${props =>
    !props.isShown &&
    css`
      display: none;
    `}

  span {
    color: ${props => props.theme.colours.main};
  }
`

export const Options = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 25px;

  min-height: 42px;
`

export const FilterButton = styled.button`
  position: relative;

  width: 120px;
  height: 48px;

  padding-left: 42px;

  background-color: transparent;
  background-image: url(${filterIcon});
  background-repeat: no-repeat;
  background-position: 16px 50%;
  background-size: 28px;

  border: 1px solid #8b8b8b;
  border-radius: 3px;

  font-size: 1.1em;

  cursor: pointer;

  p {
    display: none;
    position: absolute;
    left: 26px;
    top: 9px;

    font-size: 1.1em;
  }

  ${props =>
    props.isOpen &&
    css`
      background-color: rgba(211, 179, 42, 0.15);
    `};

  ${props =>
    props.numOfActiveFilters > 0 &&
    css`
      background-image: url(${circleIcon});

      border: 1px solid ${props.theme.colours.main};

      p {
        display: inline;
        font-family: "Open Sans", sans-serif;
        font-size: 0.8em;
        top: 13px;
        left: 26px;
      }
    `}

  @media (max-width: 976px) {
    span {
      display: none;
    }

    width: 60px;
  }
`

export const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: initial;

    ${props =>
      props.isShown &&
      css`
        height: 160px;

        margin-bottom: 20px;
      `}

    .filter-dropdown {
      width: 100%;

      :not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  ${props =>
    props.isShown
      ? css`
          height: 105px;
          opacity: 1;
        `
      : css`
          height: 0;
          opacity: 0;
        `}

  transition: height 0.2s ease-in, opacity 0.2s ease-in;
`

export const NoResultsFound = styled.div`
  margin: auto;

  padding: 100px;

  h3 {
    font-size: 1.5em;
    font-family: ${props => props.theme.fonts.primary};
    text-align: center;

    margin-bottom: 30px;
  }
`

export const ClearResultsButton = styled(CtaButton)`
  margin: auto;
`
