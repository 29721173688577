import styled, { css, keyframes } from "styled-components"

import { Button } from "../../styles/button"
import { constrictedContainer } from "../../styles/globalStyle"

export const slideUp = keyframes`
  from {
    transform: translateY(116px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`

export const slideDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  
  to {
    transform: translateY(116px);
    opacity: 0;
  }
`

export const ButtonControl = styled(Button).attrs(props => ({
  bgColor: props.isAcceptOption ? props.theme.colours.main : "#000"
}))`
  width: 108px;
  height: 32px;

  background-color: ${props => props.bgColor};
`

export const CookieConsent = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 5;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px;

  background-color: #d9d9d9;

  animation: ${slideUp} 0.75s ease-in-out forwards;

  ${props =>
    props.responseChosen &&
    css`
      animation: ${slideDown} 0.75s ease-in-out forwards;
    `}

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;

    padding: 6px;

    p {
      margin-bottom: 10px;
    }

    ${Button} {
      width: 128px;
      height: 40px;

      font-size: 0.85em;
    }
  }
`
export const CookieConsentInner = styled.div`
  ${constrictedContainer}

  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: ${props => props.theme.fonts.primary};
    font-size: 1.15em;
    color: #000;
    text-align: left;

    a {
      font-size: 0.9em;
      color: ${props => props.theme.cookieConsent.link};
    }
  }

  @media (max-width: 720px) {
    display: block;
  }
`

export const Controls = styled.div`
  display: flex;

  button:not(:last-child) {
    margin-right: 20px;
  }
`
