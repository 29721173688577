import styled, { css, keyframes } from "styled-components"

const fillAnim = (startValue, endValue, staticValue) => keyframes`
  0% {
    opacity: 0;
    stroke-dasharray: ${startValue} ${staticValue};
  }

  60% {
    opacity: 1;
    stroke-dasharray: ${endValue + 5} ${staticValue};
  }

  80% {
    opacity: 0.8;
    stroke-dasharray: ${endValue - 5} ${staticValue};
  }

  100% {
    opacity: 1;
    stroke-dasharray: ${endValue} ${staticValue};
  }
`

export const RadialPercentageBlock = styled.div`
  padding: 12%;

  font-family: ${props => props.theme.fonts.openSans};
  font-weight: 700;

  overflow: hidden;

  h3 {
    font-size: 95%;
    font-weight: 700;
    text-align: center;

    font-family: ${props => props.theme.fonts.openSans};
  }
`

const RadialPercentageCircle = styled.circle`
  stroke-width: 7.5;
  fill: transparent;
`

export const SvgText = styled.text`
  dominant-baseline: middle;
  text-anchor: middle;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 1.3em;
`

export const RadialPercentageCircleInner = styled(RadialPercentageCircle)`
  stroke: #fff;
  stroke-dasharray: 0;

  text {
    color: red;
  }
`

export const RadialPercentageCircleOuter = styled(RadialPercentageCircle)`
  stroke: ${props => props.theme.colours.main};
  stroke-dasharray: ${props => props.dashArray};

  ${props =>
    props.shouldAnimate
      ? css`
          animation: ${fillAnim(props.startValue, props.endValue, props.staticValue)} 1s ease-in-out forwards;
          animation-delay: ${props.animationDelay}s;
        `
      : css`
          stroke-dasharray: ${props.startValue} ${props.staticValue};
        `}
`
