import styled from "styled-components"

export const Brands = styled.div`
  /* overflow: hidden; */

  .brands-list {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
  }

  .brand {
    height: 95px;

    @media (min-width: ${props => props.theme.sizes.about.mobile}px) {
      height: 180px;
      min-width: 367px;
    }

    a {
      height: calc(100% - 40px);

      padding: 20px;
    }
  }

  .swiper-container {
    width: auto;
  }

  .swiper-wrapper {
    margin-bottom: 25px;
  }

  .swiper-slide {
    height: auto;
    max-height: 180px;

    @media (max-width: ${props => props.theme.sizes.about.mobile}px) {
      max-height: 100px;
    }
  }

  .swiper-pagination {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    background-color: #fff;
  }

  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.colours.main};
  }
`
