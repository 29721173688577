import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"

import { fadeInOut } from "../../styles/animation"

export const NewsGridItem = styled.article.attrs(props => ({
  sizing: props.size === "large" ? "614px" : "297px"
}))`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;

  overflow: hidden;

  ${props =>
    props.size !== "auto" &&
    css`
      width: ${props.sizing};
      height: ${props.sizing};
    `}

  background-color: #e9e9e9;

  animation: ${fadeInOut} 0.25s ease-in forwards;

  a {
    text-decoration: none;
    display: contents;
  }
`

const imageProperties = css`
  position: absolute;

  width: 100%;
  height: auto;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const Image = styled(LazyLoadImage)`
  ${imageProperties};
`

export const SwiperImage = styled.img`
  ${imageProperties};
`

export const Brand = styled.h3`
  position: relative;

  margin-bottom: 10px;
  padding: 8px;

  background-color: ${props => props.theme.news.previewInfoBg};

  color: #fff;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.3125em;
  font-weight: 700;
`

export const ArticleLink = styled(Link)`
  color: #000;
  text-decoration: none;
`

export const ReadMore = styled.p`
  color: ${props => props.theme.news.linkPreview};
`

export const ArticleContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;

  max-width: 75%;

  padding: 8px;
  margin-bottom: 10px;

  background-color: ${props => props.theme.news.previewInfoBg};

  font-family: ${props => props.theme.fonts.primary};

  a {
    margin-bottom: 10px;

    color: ${props => props.theme.colours.main};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`

export const ArticleTitle = styled.div`
  overflow: hidden;

  max-height: 42px;

  h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    margin-bottom: 10px;

    font-size: 1.3125em;
    font-weight: 700;
    color: #fff;
  }
  a,
  a:hover {
    text-decoration: none;
  }
`
