import * as PropTypes from "prop-types"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Media from "react-media"
import { Link } from "react-router-dom"

import downIcon from "../../assets/icons/icon-chevrondown.svg"
import upIcon from "../../assets/icons/icon-chevronup.svg"
import { useScrollTo } from "../../helpers"
import { brandColorData } from "../../styles/brandsColor"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const BrandPage = ({ brand, translations }) => {
  useScrollTo()

  const { brandName, slug, brandPageLogo, brandDescription, brandImage, markets, seoTitle, seoDescription, features, brandUrl } = brand

  const colours = brandColorData[slug]

  const [marketsVisible, setMarketsVisible] = useState(false)

  const handleCtaClick = () => window.open(brandUrl, "_blank", "noopener")

  const handleCollapsingClick = () => setMarketsVisible(!marketsVisible)

  return (
    <PageTemplate>
      <Helmet>
        <title data-react-helmet="true">{seoTitle}</title>
        <meta name="description" content={seoDescription} data-react-helmet="true" />
      </Helmet>

      <S.CosmeticTop colour={colours.main} />

      <S.BrandContainer colour={colours.background}>
        <S.About>
          <S.AboutInner>
            <S.Breadcrumbs textColor={colours.text}>
              <span>
                <Link to={`/${translations.brands}`}>{translations.brands}</Link>
              </span>
              <span>{`>`}</span>
              <span>{brandName}</span>
            </S.Breadcrumbs>
            <S.Logo src={brandPageLogo} alt="Brand Logo" />
            {markets?.length > 0 && (
              <S.MarketsContainer>
                <S.MarketTitle textColor={colours.text}>Markets</S.MarketTitle>
                <S.MarketsInner>
                  {markets.map((market, ind) => (
                    <S.Market key={ind} textColor={colours.text} index={ind} visible={marketsVisible}>
                      <Link to={`./${slug}/${market.countryCode}`}>
                        <S.FlagImage src={market.countryFlag} />
                        <span>{market.countryCode}</span>
                      </Link>
                    </S.Market>
                  ))}
                  <S.CollapseButton onClick={handleCollapsingClick} buttonIcon={!marketsVisible ? downIcon : upIcon} nbMarkets={markets.length}>
                    <span />
                  </S.CollapseButton>
                </S.MarketsInner>
              </S.MarketsContainer>
            )}
            <S.Description textColour={colours.text}>{brandDescription}</S.Description>
          </S.AboutInner>
          <Media
            query="(min-width: 900px)"
            render={() => (
              <S.AboutInner>
                <S.AbsoluteImage src={brandImage} alt="Brand" />
              </S.AboutInner>
            )}
          />
        </S.About>
        <S.FeatureStrip colour={colours.featureStrip.background}>
          <S.FeatureStripInner>
            {features.map((feature, ind) => (
              <S.Feature key={ind} textColour={colours.featureStrip.text}>
                <LazyLoadImage src={feature.iconUrl} alt="Brand Feature" />
                <p>{feature.title}</p>
              </S.Feature>
            ))}
          </S.FeatureStripInner>
        </S.FeatureStrip>
        <Media query="(max-width: 899px)" render={() => <S.Image src={brandImage} alt="Brand" />} />
        <S.ButtonContainer>
          <S.CtaButton brand={slug} colour={colours.cta} onClick={handleCtaClick}>
            {brandName}
          </S.CtaButton>
        </S.ButtonContainer>
      </S.BrandContainer>

      <S.CosmeticBottom colour={colours.bottom} />
    </PageTemplate>
  )
}

BrandPage.propTypes = {
  brand: PropTypes.objectOf(PropTypes.any),
  translations: PropTypes.any
}

BrandPage.defaultProps = {
  brand: {},
  translations: {}
}

export default BrandPage
