import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const Menu = ({ isVisible, checkFunction, navLinks, handleClose, handleLanguageChange, languages, userLanguage }) => {
  const [areLanguagesExpanded, setAreLanguagesExpanded] = React.useState(true)

  const handleLanguageClick = e => {
    handleLanguageChange(e)
    handleClose()
  }

  const createNavLink = link => (
    <S.NavLink key={link.text} to={link.to} smooth={link.smoothScroll} {...(link.matchPath && checkFunction(link.matchPath))} onClick={handleClose}>
      {link.text}
    </S.NavLink>
  )

  const createLanguageLink = link => {
    const isActive = link.langCode === userLanguage
    return (
      <S.LanguageLink key={link.language} imgurl={link.image} data-lang={link.langCode} isActive={isActive} onClick={handleLanguageClick}>
        {link.language}
      </S.LanguageLink>
    )
  }

  return (
    <S.Menu isVisible={isVisible}>
      <S.CloseButton aria-label="Close Menu" onClick={handleClose} />
      <S.LinkContainer>
        {navLinks.map(link => createNavLink(link))}
        {languages.length > 1 && (
          <S.LanguageContainer areLanguagesExpanded={areLanguagesExpanded}>
            <S.LanguageHeader areLanguagesExpanded={areLanguagesExpanded} onClick={() => setAreLanguagesExpanded(!areLanguagesExpanded)}>
              <h4>Languages</h4>
            </S.LanguageHeader>
            {languages.map(language => createLanguageLink(language))}
          </S.LanguageContainer>
        )}
      </S.LinkContainer>
    </S.Menu>
  )
}

Menu.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  checkFunction: PropTypes.func.isRequired,
  navLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.any).isRequired,
  userLanguage: PropTypes.string.isRequired
}

export default Menu
