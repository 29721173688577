import PropTypes from "prop-types"
import React, { useState } from "react"

import iconDown from "../../assets/icons/icon-chevrondown-white.svg"
import iconUp from "../../assets/icons/icon-chevronup-white.svg"
import { pushToDataLayer } from "../../helpers/gtmManager"
import Button from "../Button"
import * as S from "./style"

export const getSizeProps = size => {
  const defaultData = {
    width: 96,
    height: 40,
    fontSize: 0.75
  }

  if (!size) return defaultData

  switch (size) {
    default:
    case "small": {
      return defaultData
    }
    case "medium":
      return {
        width: 148,
        height: 48,
        fontSize: 0.75
      }
    case "large":
      return {
        width: 250,
        height: 80,
        fontSize: 1.125
      }
  }
}

const LoginButton = ({ options, label, size }) => {
  const [areOptionsVisible, setAreOptionsVisible] = useState(false)

  const handleDropdownClick = () => setAreOptionsVisible(!areOptionsVisible)
  const handleMouseLeave = () => setAreOptionsVisible(false)

  const properties = getSizeProps(size)
  const gtmData = { action: "login" }

  return (
    <>
      <Button onMouseLeave={handleMouseLeave} size={size} isAltColour icon={areOptionsVisible ? iconUp : iconDown} onClick={handleDropdownClick}>
        {label}
        <S.Options top={properties.height} isVisible={areOptionsVisible}>
          {options.map((opt, ind) => (
            <S.Option key={ind} href={opt.loginUrl} maxHeight={properties.height} onClick={() => gtmData && pushToDataLayer(gtmData)}>
              <p>{opt.name}</p>
            </S.Option>
          ))}
        </S.Options>
      </Button>
    </>
  )
}

LoginButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      loginUrl: PropTypes.string
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.string
}

LoginButton.defaultProps = {
  size: "small"
}

export default LoginButton
