import styled, { css } from "styled-components"

import downIcon from "../../assets/icons/icon-chevrondown.svg"
import upIcon from "../../assets/icons/icon-chevronup.svg"
import { fadeInOut } from "../../styles/animation"

export const FaqItem = styled.div`
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.fonts.primary};

  animation: ${fadeInOut} 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;

  :not(:last-child) {
    margin-bottom: 10px;
  }

  ${props =>
    !props.isOpaque &&
    css`
      opacity: 0.2;
      transition: opacity 0.35s ease-in-out;
    `}
`

export const Question = styled.div`
  margin-bottom: 10px;

  border-bottom: 1px solid #000;

  cursor: pointer;

  background-image: url(${props => (props.isOpen ? upIcon : downIcon)});
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 28px;

  h4 {
    width: 92%;

    font-weight: 700;
    font-size: 1.2125em;
    line-height: 1.5em;
  }
`

export const Answer = styled.p`
  display: none;

  font-size: 1.1em;

  ${props =>
    props.isOpen &&
    css`
      display: block;
    `};
`
