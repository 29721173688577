import leovegasAccountImage from "../assets/leovegas/faq/account.svg"
import leovegasfriendsImage from "../assets/leovegas/faq/friends.svg"
import leovegasPaymentImage from "../assets/leovegas/faq/payment.svg"
import leovegasSearchIcon from "../assets/leovegas/icon-search.svg"
import sailfishAccountImg from "../assets/sailfish/faq/account.svg"
import sailfishFriendsImg from "../assets/sailfish/faq/friends.svg"
import sailfishPaymentImg from "../assets/sailfish/faq/payment.svg"
import sailfishTestimonialHeader from "../assets/sailfish/fish.png"
import sailfishSearchIcon from "../assets/sailfish/icon-search.svg"

export const themes = {
  leovegas: {
    colours: {
      main: "#d9b500",
      black: "#000",
      white: "#fff",
      title: "#fff"
    },
    fonts: {
      primary: '"Cormorant Garamond", serif',
      secondary: '"Open Sans", serif'
    },
    fontsUrl: "https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;700&display=swap",
    button: {
      medium: {
        fontSize: "0.75"
      },
      large: {
        fontSize: "1.125"
      }
    },
    padding: {
      squashedPadding: "15px"
    },
    sizes: {
      navigation: {
        mobile: 846
      },
      header: {
        mobile: 846
      },
      footer: {
        mobile: 846
      },
      about: {
        mobile: 846
      },
      faq: {
        small: 650,
        medium: 750,
        large: 850
      },
      aboutCollapse: 800
    },
    footer: {
      bgColor: "#000"
    },
    homepageBrands: {
      bgColor: "#000"
    },
    registerContainer: {
      bgColor: "#000"
    },
    news: {
      linkPreview: "#d9b500",
      previewInfoBg: "#000"
    },
    cookieConsent: {
      link: "#d9b500"
    },
    faq: {
      icons: {
        searchIcon: leovegasSearchIcon,
        accountImage: leovegasAccountImage,
        friendsImage: leovegasfriendsImage,
        paymentImage: leovegasPaymentImage
      }
    }
  },
  sailfish: {
    colours: {
      main: "#2796cb",
      black: "#012c40",
      white: "#fff",
      title: "#fff",
      yellow: "#ffc72a"
    },
    fonts: {
      primary: '"Alumni Sans", serif',
      secondary: '"Open Sans", serif'
    },
    fontsUrl:
      "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap&family=Alumni+Sans:wght@300;400;500;600;700&display=swap",
    padding: {
      squashedPadding: "15px"
    },
    button: {
      medium: {
        fontSize: "1.1"
      },
      large: {
        fontSize: "1.5"
      }
    },
    sizes: {
      navigation: {
        mobile: 846
      },
      header: {
        mobile: 846
      },
      footer: {
        mobile: 846
      },
      about: {
        mobile: 846
      },
      faq: {
        small: 650,
        medium: 750,
        large: 850
      },
      aboutCollapse: 800
    },
    footer: {
      bgColor: "#012c40"
    },
    homepageBrands: {
      bgColor: "#012c40"
    },
    registerContainer: {
      bgColor: "#012c40"
    },
    news: {
      linkPreview: "#ffc72a",
      previewInfoBg: "#012c40"
    },
    cookieConsent: {
      link: "#2796cb"
    },
    faq: {
      icons: {
        searchIcon: sailfishSearchIcon,
        accountImage: sailfishAccountImg,
        friendsImage: sailfishFriendsImg,
        paymentImage: sailfishPaymentImg
      }
    },
    testimonials: {
      bgPageColour: "#f2f2f2",
      headerBgImage: sailfishTestimonialHeader,
      headerBgColour: "#2796cb",
      headerTextColour: "#fff",
      nameColour: "#ffc72a",
      textColour: "#094664",
      urlColour: "#1480B8"
    }
  }
}
