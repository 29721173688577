import styled from "styled-components"
// import { constrictedContainer } from "../../styles/globalStyle"

export const News = styled.div`
  overflow: hidden;

  .swiper-wrapper {
    margin-bottom: 25px;
  }

  .swiper-slide {
    height: auto;

    max-height: 297px;
    max-width: 297px !important;
  }

  .swiper-pagination {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    background-color: #000;
  }

  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.colours.main};
  }
`

export const Title = styled.h3`
  position: relative;
  z-index: 2;

  margin-bottom: 25px;

  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.3125em;
  font-weight: 700;
`
