import * as PropTypes from "prop-types"
import React from "react"
import { useTheme } from "styled-components"

import FaqItem from "../../components/FaqItem"
import Search from "../../components/Search"
import { useScrollTo } from "../../helpers"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const FaqPage = ({ faq, translations }) => {
  useScrollTo()

  const faqData = faq
  const [searchText, setSearchText] = React.useState("")
  const [selectedFilter, setSelectedFilter] = React.useState("")
  const [faqs, setFaqs] = React.useState(faqData)

  const handleSearchChange = e => setSearchText(e.target.value)
  const handleSearchClear = () => setSearchText("")

  const handleFilterClick = filter => {
    if (filter === selectedFilter) {
      setSelectedFilter("")

      return
    }

    setSelectedFilter(filter)
  }

  React.useEffect(() => {
    const query = searchText.toLowerCase()

    if (query === "") {
      setFaqs(faqData)
      return
    }

    setFaqs(faqData.filter(item => item.question.toLowerCase().indexOf(query) > -1 || item.answer.toLowerCase().indexOf(query) > -1))
  }, [searchText, faqData])

  const theme = useTheme()

  const faqCategoryData = [
    {
      id: "Account",
      title: translations.faqAccount,
      text: translations.faqAccountSubTitle,
      image: theme.faq.icons.accountImage
    },
    {
      id: "Payments",
      title: translations.faqPayments,
      text: translations.faqPaymentsSubTitle,
      image: theme.faq.icons.paymentImage
    },
    {
      id: "Friends of Leo",
      title: translations.faqFriendsOfLeo,
      text: translations.faqFriendsOfLeoSubTitle,
      image: theme.faq.icons.friendsImage
    }
  ]

  return (
    <PageTemplate>
      <S.Header>
        <Search
          text={searchText}
          onChange={handleSearchChange}
          onClear={handleSearchClear}
          width="48vw"
          height="60px"
          marginBottom="60px"
          translations={translations}
        />
        <S.FilterContainer>
          {faqCategoryData.map(filter => (
            <S.Filter
              key={filter.id}
              src={filter.image}
              {...(selectedFilter === filter.id && { $isSelected: true })}
              onClick={() => handleFilterClick(filter.id)}
            >
              <h4>{filter.title}</h4>
              <p>{filter.text}</p>
            </S.Filter>
          ))}
        </S.FilterContainer>
      </S.Header>
      <S.FaqContainer>
        {searchText !== "" && faqs.length > 0 && <S.ActiveSearchText>{`${translations.faqSearchActiveMessage} ${searchText}`}</S.ActiveSearchText>}
        {faqs.map((entry, ind) => (
          <FaqItem key={ind} currentFilterId={selectedFilter} data={{ id: ind + 1, ...entry }} />
        ))}
        {faqs.length === 0 && (
          <S.NoResultsFound>
            <h3>{translations.noFaqFound}</h3>
            <S.ClearResultsButton onClick={() => setSearchText("")}>{translations.clear}</S.ClearResultsButton>
          </S.NoResultsFound>
        )}
      </S.FaqContainer>
    </PageTemplate>
  )
}

FaqPage.propTypes = {
  faq: PropTypes.arrayOf(PropTypes.any).isRequired,
  translations: PropTypes.objectOf(PropTypes.any)
}

FaqPage.defaultProps = {
  translations: {}
}

export default FaqPage
