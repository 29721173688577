import { LazyLoadImage } from "react-lazy-load-image-component"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

export const TestimonialItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Content = styled.div`
  margin: 0px 20px;
  padding: 50px 20px 30px;
  background: #fff;
  @media (min-width: 425px) {
    height: 200px;
  }
`
export const LogoContainer = styled.div`
  align-self: center;
  height: 60px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.26);
  padding: 25px;
  background: #fff;
  transform: translateY(55px);
`

export const Logo = styled(LazyLoadImage)`
  max-height: 60px;
`

export const Text = styled(ReactMarkdown)`
  margin-top: 15px;
  color: ${props => props.theme.testimonials.textColour};
  font-style: italic;
  font-weight: 500;
`

export const Name = styled.p`
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.testimonials.nameColour};
`

export const Url = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  font-weight: bold;
  color: ${props => props.theme.testimonials.urlColour};

  text-decoration: none;
`
