import styled, { css } from "styled-components"

import { fadeInOutSubtle } from "../../styles/animation"

export const Page = styled.div`
  animation: ${fadeInOutSubtle} 0.25s ease-in-out;

  display: flex;
  flex-direction: column;

  flex: 1;

  ${props =>
    props.type === "testimonial" &&
    css`
      background: ${props.theme.testimonials.bgPageColour};
    `}
`
