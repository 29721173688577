import PropTypes from "prop-types"
import React from "react"
import SwiperCore, { Pagination, A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

import { useScreenSize } from "../../utils/hooks"
import BrandItem from "../BrandItem"
import HomeSection from "../HomeSection"
import Title from "../Title"
import * as S from "./style"

const Brands = ({
  data: {
    config,
    config: { bgTitle },
    content: { brands }
  }
}) => {
  const screenSize = useScreenSize()

  const itemsNumber = brands.length
  const hasItems = screenSize.width.lessThan(768) ? itemsNumber > 1 : itemsNumber > 3

  const carouselItems = brands.map(({ brandLogo, slug }, ind) => {
    const item = {
      brandLogo,
      slug
    }
    return (
      <div key={ind} className="brand">
        <BrandItem {...item} key={slug} imageSrc={brandLogo} routePath={`/${bgTitle.toLowerCase()}/${slug}`} />
      </div>
    )
  })

  const params = {
    spaceBetween: 30,
    loop: true,
    breakpoints: {
      0: {
        slidesPerView: "2",
        centeredSlides: true
      },
      800: {
        slidesPerView: "3"
      }
    },
    watchOverflow: true,
    autoplay: {
      delay: 3000
    },
    slideClass: "swiper-slide",
    pagination: {
      clickable: true
    }
  }

  SwiperCore.use([Autoplay, Pagination, A11y])

  return (
    <HomeSection config={config}>
      <Title isInverted>{bgTitle}</Title>
      <S.Brands>
        {hasItems ? (
          <Swiper {...params}>
            {carouselItems.map((ci, ind) => (
              <SwiperSlide key={ind}>{ci}</SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="brands-list">{carouselItems}</div>
        )}
      </S.Brands>
    </HomeSection>
  )
}

Brands.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      bgColor: PropTypes.string,
      bgTitle: PropTypes.string,
      bgTitleColor: PropTypes.string,
      outboundLink: PropTypes.string,
      outboundText: PropTypes.string,
      outboundLinkColor: PropTypes.string
    }).isRequired,
    content: PropTypes.shape({
      brands: PropTypes.arrayOf.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      translations: PropTypes.any
    }).isRequired
  }).isRequired
}

export default Brands
