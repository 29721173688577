import styled from "styled-components"

export const DownloadableLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin: 50px 0;
`
export const DownloadText = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding-bottom: 20px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  background: rgba(255, 255, 255, 0.7);

  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  span {
    font-weight: bold;
    font-size: 0.9em;
    text-transform: uppercase;

    background: #000;
    color: #fff;

    margin: 0 auto;
    padding: 10px;
  }
`

export const DownloadableLogo = styled.div`
  position: relative;
  margin: 20px;
  padding: 40px;

  width: 270px;
  height: 170px;

  display: flex;
  align-items: center;

  cursor: pointer;

  box-sizing: border-box;

  border: 1px solid rgba(0, 0, 0, 0.075);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);

  img {
    max-width: 100%;
    max-height: 100%;

    margin: 0 auto;
  }

  :hover {
    ${DownloadText} {
      visibility: visible;
      opacity: 1;

      transition: all 0.3s ease-in-out;
    }
  }
`
