import * as PropTypes from "prop-types"
import React from "react"
import Masonry from "react-masonry-css"

import TestimonialItem from "../../components/TestimonialItem"
import Title from "../../components/Title"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const Testimonials = ({ testimonials, translations }) => {
  const breakpoints = {
    default: 3,
    1100: 3,
    800: 2,
    500: 1
  }

  return (
    <PageTemplate type="testimonial">
      <S.Header>
        <S.HeaderContent>
          <Title isInverted>{translations.testimonials}</Title>
          <S.HeaderSubTitle>{translations.testimonialsPageHeader}</S.HeaderSubTitle>
        </S.HeaderContent>
      </S.Header>
      <S.TestimonialsContainer>
        <Masonry breakpointCols={breakpoints} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
          {testimonials.map((item, ind) => (
            <TestimonialItem key={ind} data={item} />
          ))}
        </Masonry>
      </S.TestimonialsContainer>
    </PageTemplate>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.any).isRequired,
  translations: PropTypes.objectOf(PropTypes.any)
}

Testimonials.defaultProps = {
  translations: {}
}

export default Testimonials
