import PropTypes from "prop-types"
import React from "react"

import NewsListItem from "../NewsListItem"
import * as S from "./style"

const NewsList = ({ articles, pageWidth, translations }) => (
  <S.ListArticles>
    {articles.map((article, ind) => (
      <NewsListItem key={ind} data={article} isSmall={pageWidth <= 550} translations={translations} />
    ))}
  </S.ListArticles>
)

NewsList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageWidth: PropTypes.number.isRequired,
  translations: PropTypes.objectOf(PropTypes.any)
}

NewsList.defaultProps = {
  translations: {}
}

export default NewsList
