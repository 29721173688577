import { useEffect, useState } from "react"

const Dimension = {
  WIDTH: "width",
  HEIGHT: "height"
}

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: 0,
    height: 0
  })

  useEffect(() => {
    const handleWindowResize = () => setScreenSize({ width: window.innerWidth, height: window.innerHeight })

    window.addEventListener("resize", handleWindowResize)

    handleWindowResize()

    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  const between = (dimension, from, to) => (!from || !to ? null : screenSize[dimension] >= from && screenSize[dimension] <= to)
  const lessThan = (dimension, number) => number && screenSize[dimension] < number
  const lessThanOrEqualTo = (dimension, number) => number && screenSize[dimension] <= number
  const greaterThan = (dimension, number) => number && screenSize[dimension] > number
  const greaterThanOrEqualTo = (dimension, number) => number && screenSize[dimension] >= number
  const isMobile = screenSize[Dimension.WIDTH] <= 512

  return {
    width: {
      value: screenSize.width,
      between: (from, to) => between(Dimension.WIDTH, from, to),
      lessThan: value => lessThan(Dimension.WIDTH, value),
      lessThanOrEqualTo: value => lessThanOrEqualTo(Dimension.WIDTH, value),
      greaterThan: value => greaterThan(Dimension.WIDTH, value),
      greaterThanOrEqualTo: value => greaterThanOrEqualTo(Dimension.WIDTH, value)
    },
    height: {
      value: screenSize.height,
      between: (from, to) => between(Dimension.HEIGHT, from, to),
      lessThan: value => lessThan(Dimension.HEIGHT, value),
      lessThanOrEqualTo: value => lessThanOrEqualTo(Dimension.HEIGHT, value),
      greaterThan: value => greaterThan(Dimension.HEIGHT, value),
      greaterThanOrEqualTo: value => greaterThanOrEqualTo(Dimension.HEIGHT, value)
    },
    isMobile
  }
}
