import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

export const Footer = styled.section`
  padding: 60px;

  background-color: ${props => props.theme.footer.bgColor};

  color: #fff;
  font-family: ${props => props.theme.fonts.primary};
`

export const Section = styled.div`
  min-width: 128px;

  h3 {
    margin-bottom: 10px;

    font-size: 1.3125em;
    font-weight: 700;
  }

  li {
    font-size: 0.875em;
    line-height: 21px;
    list-style: none;
  }

  a {
    color: #fff;
    text-decoration: none;

    :hover {
      color: ${props => props.theme.colours.main};
    }
  }
`

export const SocialMediaLink = styled.a`
  svg {
    width: 28px;
    height: 28px;

    :hover {
      path {
        fill: ${props => props.theme.colours.main};
      }
    }
  }

  :not(:last-child) {
    margin-right: 8px;
  }
`

export const Inner = styled.div`
  ${constrictedContainer}

  display: flex;
  justify-content: space-evenly;

  @media (max-width: ${props => props.theme.sizes.footer.mobile}px) {
    position: relative;

    flex-direction: column;
    align-items: flex-start;

    ${Section} {
      :not(:last-child) {
        margin-bottom: 40px;
      }
    }

    ${Section}:first-child {
      margin-top: 60px;
    }
  }
`
