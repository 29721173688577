import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const FaqItem = ({ currentFilterId, data: { id, category = [], question, answer } }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <S.FaqItem isOpaque={currentFilterId === "" || category.includes(currentFilterId)}>
      <S.Question isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <h4>{`${id}. ${question}`}</h4>
      </S.Question>
      <S.Answer isOpen={isOpen}>{answer}</S.Answer>
    </S.FaqItem>
  )
}

FaqItem.propTypes = {
  currentFilterId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    category: PropTypes.arrayOf(PropTypes.any),
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired
  }).isRequired
}

export default FaqItem
