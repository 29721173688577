import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const TestimonialItem = ({ data }) => {
  const { logo, affiliateName, text, url } = data

  return (
    <S.TestimonialItem>
      <S.LogoContainer>
        <a href={`https://${url}`} target="_blank" rel="noreferrer">
          <S.Logo src={logo} alt={affiliateName} />
        </a>
      </S.LogoContainer>
      <S.Content>
        <S.Name>{affiliateName}</S.Name>
        <S.Text>{text}</S.Text>
        <S.Url href={`https://${url}`} target="_blank">
          {url}
        </S.Url>
      </S.Content>
    </S.TestimonialItem>
  )
}

TestimonialItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    logo: PropTypes.string.isRequired,
    affiliateName: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
}

export default TestimonialItem
