/* eslint-disable max-len */
import * as PropTypes from "prop-types"
import React from "react"
import { useTheme } from "styled-components"

// import HeroSlider from "../../components/HeroSlider"
import About from "../../components/About"
import Brands from "../../components/Brands"
import Button from "../../components/Button"
// import Testimonials from "../../components/Testimonials"
import Commission from "../../components/Commission"
import Contact from "../../components/Contact"
import Hero from "../../components/Hero"
import News from "../../components/News"
import { LARGE } from "../../helpers/constants"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

// const testimonialData = {
//   config: {
//     bgColor: "#fff",
//     bgTitle: "Testimonials",
//     outboundLink: "/brands",
//     outboundText: "Read all testimonials >"
//   },
//   content: {
//     testimonials: [
//     ]
//   }
// };

const HomePage = ({ homePage, translations }) => {
  const {
    bannerSection,
    aboutTitle,
    aboutText,
    brandsList,
    commissionTitle,
    commissionText,
    value1,
    value2,
    value3,
    value4,
    value1Text,
    value2Text,
    value3Text,
    value4Text,
    news
  } = homePage

  const theme = useTheme()

  const aboutData = {
    config: {
      bgTitle: aboutTitle
    },
    content: {
      title: aboutTitle,
      text: aboutText,
      ctaText: translations.register
    }
  }

  const brandData = {
    config: {
      bgColor: theme.homepageBrands.bgColor,
      bgTitle: translations.brands,
      bgTitleColor: "rgba(255, 255, 255, 0.2)",
      outboundLink: translations.brands.toLowerCase(),
      outboundText: translations.allBrandsLink,
      outboundLinkColor: "#fff"
    },
    content: {
      brands: brandsList
    }
  }

  const commissionData = {
    config: {
      bgColor: "#EDEDED",
      bgTitle: commissionTitle
    },
    content: {
      title: commissionTitle,
      text: commissionText,
      commissions: [
        { id: 0, title: value1Text, value: value1 },
        { id: 1, title: value2Text, value: value2 },
        { id: 2, title: value3Text, value: value3 },
        { id: 3, title: value4Text, value: value4 }
      ]
    }
  }

  const newsData = {
    config: {
      padding: "large",
      bgTitle: translations.news,
      outboundLink: `/${translations.news.toLowerCase()}`,
      outboundText: translations.allNewsLink,
      outboundLinkColor: "#000"
    },
    content: {
      title: translations.news,
      articles: news
    }
  }

  const contactData = {
    config: {
      bgColor: "#EDEDED",
      padding: "large"
    },
    translations
  }

  return (
    <PageTemplate>
      {/* <HeroSlider data={bannerSection} /> */}
      <Hero
        header={bannerSection[0].header}
        subHeader={bannerSection[0].subHeader}
        media={bannerSection[0].media}
        mediaType={bannerSection[0].mediaType}
        mobileMedia={bannerSection[0].mobileMedia}
        mobileMediaType={bannerSection[0].mobileMediaType}
      />

      <About data={aboutData} />

      <Brands data={brandData} />

      <Commission data={commissionData} />

      {newsData.content.articles.length > 0 && <News data={newsData} translations={translations} />}

      <S.RegisterContainer>
        <Button to="/register" size={LARGE} gtmData={{ action: "register", label: "bottom" }}>
          {translations.register}
        </Button>
      </S.RegisterContainer>

      {/* <Testimonials data={testimonialData} /> */}
      <Contact data={contactData} />
    </PageTemplate>
  )
}

HomePage.propTypes = {
  homePage: PropTypes.objectOf(PropTypes.any).isRequired,
  translations: PropTypes.objectOf(PropTypes.any).isRequired
}

export default HomePage
