import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import * as S from "./style"

const videoMediaType = "video/mp4"

const Hero = ({ header, subHeader, media: desktopMedia, mediaType: desktopMediaType, mobileMedia, mobileMediaType }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768)

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const Media = () => {
    const mediaType = isMobile ? mobileMediaType : desktopMediaType
    const media = isMobile ? mobileMedia : desktopMedia

    if (mediaType === videoMediaType) {
      return (
        <S.HeroVideo autoPlay muted loop playsInline>
          <source src={media} type={videoMediaType} />
        </S.HeroVideo>
      )
    }

    return <S.HeroImage src={media} alt="Hero" />
  }

  return (
    <S.Hero className="hero">
      <Media />
      <S.HeroInner>
        <S.HeroContainer>
          <S.HeroTitle>{header}</S.HeroTitle>
          <S.HeroDescription>{subHeader}</S.HeroDescription>
        </S.HeroContainer>
      </S.HeroInner>
    </S.Hero>
  )
}

Hero.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  media: PropTypes.string,
  mediaType: PropTypes.string,
  mobileMedia: PropTypes.string,
  mobileMediaType: PropTypes.string
}
Hero.defaultProps = {
  header: "",
  subHeader: "",
  media: "",
  mediaType: "",
  mobileMedia: "",
  mobileMediaType: ""
}

export default Hero
