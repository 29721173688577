import PropTypes from "prop-types"
import React from "react"
import SwiperCore, { Pagination, A11y, Autoplay, Lazy } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

import HomeSection from "../HomeSection"
import NewsGridItem from "../NewsGridItem"
import Title from "../Title"
import * as S from "./style"

const swiperParams = {
  spaceBetween: 15,
  loop: true,
  slidesPerView: "auto",
  centeredSlides: true,
  watchOverflow: true,
  autoplay: {
    delay: 5000
  },
  pagination: {
    clickable: true
  },
  preloadImages: false,
  lazy: true,
  watchSlidesVisibility: true
}

SwiperCore.use([Autoplay, Pagination, A11y, Lazy])

const News = ({
  data: {
    config,
    content: { title, articles }
  },
  translations
}) => {
  const newsComponents = articles.slice(0, 8).map((article, i) => (
    <div key={i}>
      <NewsGridItem size="" data={article} translations={translations} isSwiperItem />
    </div>
  ))

  return (
    <HomeSection config={config}>
      <S.News>
        <Title>{title}</Title>
        <Swiper {...swiperParams}>
          {newsComponents.map((nc, ind) => (
            <SwiperSlide key={ind}>{nc}</SwiperSlide>
          ))}
        </Swiper>
      </S.News>
    </HomeSection>
  )
}

News.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      bgColor: PropTypes.string,
      bgTitle: PropTypes.string,
      bgTitleColor: PropTypes.string,
      outboundLink: PropTypes.string,
      outboundText: PropTypes.string,
      outboundLinkColor: PropTypes.string
    }).isRequired,
    content: PropTypes.shape({
      title: PropTypes.string.isRequired,
      articles: PropTypes.arrayOf.isRequired
    }).isRequired
  }).isRequired,
  translations: PropTypes.objectOf(PropTypes.any)
}

News.defaultProps = {
  translations: {}
}
export default News
