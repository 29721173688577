/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import * as PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import * as ReactDOM from "react-dom"
import { Helmet } from "react-helmet"
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom"
import { ThemeProvider } from "styled-components"

import CookieConsent from "./components/CookieConsent"
import Footer from "./components/Footer"
import NavBar from "./components/NavBar"
import NotFound from "./components/NotFound"
import { getMenu } from "./helpers"
import fetchData from "./helpers/getData"
import { initialize } from "./helpers/gtmManager"
import BrandPage from "./pages/Brand"
import BrandsPage from "./pages/Brands"
import Downloads from "./pages/Downloads"
import FaqPage from "./pages/Faq"
import HomePage from "./pages/Home"
import InfoPage from "./pages/Info"
import MarketPage from "./pages/Market"
import NewsPage from "./pages/News"
import NewsArticlePage from "./pages/NewsArticle"
import Registration from "./pages/Registration"
import RegistrationOptionsPage from "./pages/RegistrationOptionsPage"
import Testimonials from "./pages/Testimonials"
import * as serviceWorker from "./serviceWorker"
import * as S from "./style"
import GlobalStyle from "./styles/globalStyle"
import { themes } from "./styles/themes"

const App = () => {
  useEffect(() => initialize(), [])

  const { REACT_APP_LANG: siteLang } = process.env
  const availableLang = siteLang.split(",").map(language => language)

  const storedLanguage = availableLang.length > 1 && localStorage.getItem("language")

  const [data, setData] = useState()
  const [lang, setLang] = useState(storedLanguage || availableLang[0])

  const [cookies, setCookie] = useCookies(["affiliates-cookies"])

  const [lockBodyScroll, setLockBodyScroll] = useState(false)

  const handleCookieAccept = () => setTimeout(() => setCookie("hasAcceptedCookiePolicy", true), 350)
  const handleCookieDecline = () => setTimeout(() => setCookie("hasAcceptedCookiePolicy", false), 350)

  const handleLanguageChange = e => {
    const language = e.target.getAttribute("data-lang")

    localStorage.setItem("language", language)
    setLang(language)
    window.location.href = "/"
  }

  useEffect(() => {
    const getData = async () => setData(await fetchData(lang))
    getData()
  }, [lang])

  // Scroll page to top on every route change
  const ScrollToTop = ({ children }) => {
    const location = useLocation()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [location])

    return <>{children}</>
  }

  ScrollToTop.propTypes = {
    children: PropTypes.node.isRequired
  }

  const getRoutes = () => {
    let routes = <></>

    if (data) {
      const { homePage, brands, markets, faq, news, categories, testimonials, translations, pages, downloads } = data

      const { registrationForm } = homePage

      routes = (
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <HomePage homePage={homePage} news={news} translations={translations} />
            </Route>
            <Route exact path={`/${translations.brands}`}>
              <BrandsPage brands={brands} translations={translations} />
            </Route>
            <Route
              exact
              path={`/${translations.brands}/:id`}
              component={({
                match: {
                  params: { id }
                }
              }) => {
                const brand = brands.find(item => item.slug === id)

                return brand ? <BrandPage brand={brand} translations={translations} /> : <NotFound />
              }}
            />
            <Route
              exact
              path={`/${translations.brands}/:brandId/:marketId`}
              component={({
                match: {
                  params: { brandId, marketId }
                }
              }) => {
                const brand = brands.find(item => item.slug === brandId)
                const market = markets.find(
                  item =>
                    item.countryCode.toLowerCase() === marketId.toLowerCase() && item.brandId === brand.slug && item.brandId === brandId.toLowerCase()
                )

                return market ? <MarketPage market={market} brandName={brand.brandName} translations={translations} /> : <NotFound />
              }}
            />
            {faq && (
              <Route exact path={`/${translations.faq}`}>
                <FaqPage faq={faq} translations={translations} />
              </Route>
            )}
            {news && (
              <Route exact path="/news">
                <NewsPage news={news} categories={categories} translations={translations} />
              </Route>
            )}
            {news && (
              <Route
                exact
                path="/news/:slug"
                component={({
                  match: {
                    params: { slug }
                  }
                }) => {
                  const newsArticle = news.find(article => article.slug === slug)

                  if (newsArticle) {
                    const relatedArticles = news
                      .filter(
                        article =>
                          article.categories &&
                          article.categories.some(item => newsArticle.categories.includes(item)) &&
                          article.slug !== newsArticle.slug
                      )
                      .slice(0, 4)

                    return <NewsArticlePage article={newsArticle} related={relatedArticles} translations={translations} />
                  }

                  return <NotFound />
                }}
              />
            )}
            {testimonials.length > 1 && (
              <Route exact path={`/${translations.testimonials}`}>
                <Testimonials testimonials={testimonials} translations={translations} />
              </Route>
            )}
            <Route exact path={`/${translations.register}`}>
              <RegistrationOptionsPage translations={translations} brands={brands} registrationOptions={registrationForm} />
            </Route>
            {downloads.length > 1 && (
              <Route exact path="/downloads">
                <Downloads downloads={downloads} translations={translations} />
              </Route>
            )}
            {registrationForm.length > 1 && (
              <Route
                exact
                path={`/${translations.register}/:id`}
                component={({
                  match: {
                    params: { id }
                  }
                }) => {
                  const brand = registrationForm.find(item => item.brand === id)

                  return <Registration translations={translations} url={brand.url} />
                }}
              />
            )}
            <Route
              exact
              path="/:id"
              component={({
                match: {
                  params: { id }
                }
              }) => {
                const page = pages.find(item => item.slug === id)

                return page ? <InfoPage page={page} /> : <NotFound />
              }}
            />
          </Switch>
        </ScrollToTop>
      )
    }

    return routes
  }

  if (!data) return null

  const { homePage, footerLinks, testimonials, translations, downloads, brandLoginOptions, news } = data

  const { seoTitle, seoDescription, registrationForm } = homePage

  const { REACT_APP_THEME: theme } = process.env

  const handleBodyScrollLockChange = value => setLockBodyScroll(value)

  const hasNews = news?.length > 0

  const hasTestimonials = testimonials?.length > 0

  const hasDownloads = downloads?.length > 0

  const navLinks = getMenu(translations, hasNews, hasTestimonials, hasDownloads)
  const { fontsUrl } = themes[theme]

  return (
    <ThemeProvider theme={themes[theme]}>
      <Router>
        <Helmet>
          <title data-react-helmet="true">{seoTitle}</title>
          <meta name="description" content={seoDescription} data-react-helmet="true" />
          <link rel="stylesheet" href={fontsUrl} />
          <link rel="icon" href={require(`./assets/favicon/${theme}/favicon.ico`).default} />
        </Helmet>
        <S.MainContainer>
          <GlobalStyle $lockBodyScroll={lockBodyScroll} />
          {translations && (
            <NavBar
              userLanguage={lang}
              handleLanguageChange={handleLanguageChange}
              navLinks={navLinks}
              translations={translations}
              registerOptions={registrationForm}
              brandLoginOptions={brandLoginOptions}
              handleBodyScrollLockChange={handleBodyScrollLockChange}
              logo={homePage.logo}
              siteLang={availableLang}
            />
          )}

          {getRoutes()}
          {!cookies.hasAcceptedCookiePolicy && translations && (
            <CookieConsent onAcceptClick={handleCookieAccept} onDeclineClick={handleCookieDecline} translations={translations} />
          )}
          {footerLinks && <Footer footerLinks={footerLinks} navLinks={navLinks} translations={translations} hasNews={hasNews} />}
        </S.MainContainer>
      </Router>
    </ThemeProvider>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
