import * as PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"

import * as S from "./style"

const CookieConsent = ({ onAcceptClick, translations }) => {
  const [responseChosen, setResponseChosen] = React.useState(false)

  const handleAcceptClick = () => {
    onAcceptClick()

    setResponseChosen(true)
  }

  return (
    <S.CookieConsent responseChosen={responseChosen}>
      <S.CookieConsentInner>
        <p>
          {`${translations.cookiePolicyDisclaimer} `}
          <Link to="/cookie-policy">{translations.cookiePolicyLink}</Link>
        </p>

        <S.Controls>
          <S.ButtonControl isAcceptOption onClick={handleAcceptClick}>
            {translations.cookieAccept}
          </S.ButtonControl>
        </S.Controls>
      </S.CookieConsentInner>
    </S.CookieConsent>
  )
}

CookieConsent.propTypes = {
  onAcceptClick: PropTypes.func.isRequired,
  translations: PropTypes.objectOf(PropTypes.any)
}
CookieConsent.defaultProps = {
  translations: {}
}
export default CookieConsent
