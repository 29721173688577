import PropTypes from "prop-types"
import React, { useState } from "react"

import { encode } from "../../helpers"
import { NAME, SURNAME, EMAIL, MESSAGE, ACCEPT, CONTACT, MEDIUM, POST, SUBMIT, SUCCESS, ERROR, BOT_FIELD } from "../../helpers/constants"
import Button from "../Button"
import HomeSection from "../HomeSection"
import * as S from "./style"

const Contact = ({ data: { config, translations } }) => {
  const [alertType, setAlertType] = useState(null)
  const [isHidden, setIsHidden] = useState(false)

  const alerts = {
    success: translations.successMessageAlert,
    error: translations.errorMessageAlert
  }

  const initialFields = {
    name: null,
    surname: null,
    email: null,
    message: null,
    accept: false
  }

  const [fields, setFields] = useState(initialFields)
  const [changedFields, setChangedFields] = useState(initialFields)
  const [validatedFields, setValidatedFields] = useState(initialFields)

  const hideAlert = () => setTimeout(() => setAlertType(null), 4000)

  const hideForm = () => setTimeout(() => setIsHidden(true), 1500)

  const sendData = () => {
    const url = "/"
    const init = {
      method: POST,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": CONTACT, ...fields })
    }

    fetch(url, init)
      .then(() => {
        setAlertType(SUCCESS)
        // setFields(initialFields)

        hideForm()
      })
      .catch(() => setAlertType(ERROR))
  }

  const handleChange = ({ target: { name, value, checked } }) => {
    let isValid

    switch (name) {
      default: {
        isValid = value.trim().length > 0
        setFields({ ...fields, ...{ [name]: value } })
        break
      }
      case EMAIL: {
        isValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        setFields({ ...fields, ...{ [name]: value } })
        break
      }
      case ACCEPT: {
        isValid = checked
        setFields({ ...fields, ...{ [name]: checked } })
        break
      }
    }

    if (!changedFields[name]) {
      setChangedFields({ ...changedFields, ...{ [name]: true } })
    }

    setValidatedFields({ ...validatedFields, ...{ [name]: isValid } })
  }

  const handleSubmit = e => {
    e.preventDefault()

    sendData()
    hideAlert()

    document.activeElement.blur()
  }

  const hasError = name => changedFields[name] && !validatedFields[name]

  const isDisabled = !Object.values(validatedFields).every(field => field)

  return (
    <HomeSection id={CONTACT} config={config}>
      <S.Contact>
        <h3>{translations.getInTouch}</h3>

        {alertType && <S.Alert type={alertType}>{alerts[alertType]}</S.Alert>}

        <S.Form
          name={CONTACT}
          form-name={CONTACT}
          method={POST}
          data-netlify
          netlify-honeypot={BOT_FIELD}
          data-netlify-honeypot={BOT_FIELD}
          onChange={handleChange}
          onSubmit={handleSubmit}
          isHidden={isHidden}
        >
          <S.TextInput name={NAME} placeholder={translations.name} error={hasError(NAME)} aria-label="Name" />
          <S.TextInput name={SURNAME} placeholder={translations.surname} error={hasError(SURNAME)} aria-label="Surname" />
          <S.EmailInput name={EMAIL} placeholder={translations.email} error={hasError(EMAIL)} aria-label="Email" />
          <S.TextArea name={MESSAGE} placeholder={translations.message} error={hasError(MESSAGE)} aria-label="Message" />
          <S.CheckBoxContainer>
            <S.CheckBox name={ACCEPT} error={hasError(ACCEPT)} aria-label="Accept Terms & Conditions" />
            <S.AcceptTermsLink>{translations.contactAcceptanceText}</S.AcceptTermsLink>
          </S.CheckBoxContainer>

          <S.HiddenInput name={BOT_FIELD} />

          <Button size={MEDIUM} type={SUBMIT} isDisabled={isDisabled} gtmData={{ action: "contact", label: "submit" }}>
            {translations.contact}
          </Button>
        </S.Form>
      </S.Contact>
    </HomeSection>
  )
}

Contact.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      bgColor: PropTypes.string,
      bgTitle: PropTypes.string,
      bgTitleColor: PropTypes.string,
      outboundLink: PropTypes.string,
      outboundText: PropTypes.string,
      outboundLinkColor: PropTypes.string
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    translations: PropTypes.object.isRequired
  }).isRequired
}

export default Contact
