import styled from "styled-components"

import paginationNextIcon from "../../assets/icons/icon-next.svg"
import paginationPrevIcon from "../../assets/icons/icon-previous.svg"

export const Pagination = styled.div`
  margin: 0 auto 60px auto;

  .container {
    display: flex;
    align-items: center;

    height: 42px;

    font-family: ${props => props.theme.fonts.openSans};
    font-weight: 700;
  }

  .page,
  .break {
    :hover {
      background-color: ${props => props.theme.colours.main};

      a {
        color: #fff;
      }
    }

    a {
      display: block;

      color: #8b8b8b;
      text-align: center;
      font-size: 0.7em;
      line-height: 32px;
    }
  }

  .selected {
    background-color: ${props => props.theme.colours.main};

    a {
      color: #fff;
    }
  }

  .page,
  .previous-button,
  .next-button,
  .break {
    width: 32px;
    height: 32px;

    background-size: 32px;

    cursor: pointer;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .previous-button {
    background-image: url(${paginationPrevIcon});
  }

  .next-button {
    background-image: url(${paginationNextIcon});
  }
`
