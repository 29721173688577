import styled from "styled-components"

export const GridArticles = styled.div`
  display: grid;
  grid-template-columns: 614px 297px 297px;
  grid-auto-rows: 297px;
  grid-gap: 20px;

  margin-bottom: 40px;

  article:first-child {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 614px 1fr;
  }

  @media (max-width: 976px) {
    grid-template-columns: 1fr;

    article:first-child {
      grid-column: 1;
      grid-row: 1;
    }
  }
`
