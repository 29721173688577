import { LazyLoadImage } from "react-lazy-load-image-component"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import clockIcon from "../../assets/icons/icon-clock.svg"
import { constrictedContainer, markdownStyling } from "../../styles/globalStyle"

export const Banner = styled.div`
  @media (min-width: 846px) {
    max-height: 720px;
  }
`

export const BannerTitle = styled.h1`
  position: absolute;
  bottom: 40px;
  left: calc(50% - 960px);

  width: 70%;
  max-width: 1440px;

  background-color: ${props => props.theme.news.previewInfoBg};

  padding: 25px 25px 25px 10%;

  font-size: 3.2em;
  font-weight: 700;
  color: #fff;

  @media (max-width: 1920px) {
    left: 0;
  }

  @media (max-width: 1500px) {
    font-size: 2.4em;
  }

  @media (max-width: 1000px) {
    font-size: 1.8em;
  }
`

export const BannerInner = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;

    @media (min-width: 1920px) {
      width: 1920px;
      height: 720px;
    }
  }
`

export const WebTitle = styled.h1`
  position: relative;
  z-index: 2;

  width: 650px;

  padding: 50px 50px 50px 0;

  font-size: 3.2em;
  font-weight: 700;
  color: #fff;
`

export const NewsArticle = styled.article`
  /* margin-top: 120px; */
  margin-top: 60px;

  font-family: ${props => props.theme.fonts.primary};

  @media (max-width: 1233px) {
    ${WebTitle} {
      width: 425px;

      font-size: 2.1em;
    }
  }

  @media (max-width: 855px) {
    margin-top: 60px;

    ${Banner} {
      flex-direction: column;
      align-items: flex-start;

      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
`

export const ContentContainer = styled.div`
  ${constrictedContainer};

  padding: 15px;
`

export const Date = styled.p`
  height: 20px;

  margin-bottom: 20px;

  background-image: url(${clockIcon});
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 0% 3px;

  color: ${props => props.theme.colours.black};
  font-size: 1.1em;
  font-weight: 700;
  text-indent: 20px;
`

export const Related = styled.div`
  display: flex;
  flex-direction: column;

  border-top: 1px solid rgb(233, 233, 233);
  padding: 25px 0;
`

export const RelatedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 30px;

  font-weight: 700;

  h3 {
    font-size: 2em;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    font-size: 1.1em;
    color: ${props => props.theme.colours.black};

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const RelatedArticles = styled.div`
  display: flex;

  margin-bottom: 30px;

  article:not(:last-child) {
    margin-right: 20px;
  }

  @media (max-width: 1310px) {
    article:nth-child(4) {
      display: none;
    }

    article:nth-child(3) {
      margin-right: 0;
    }
  }

  @media (max-width: 982px) {
    flex-direction: column;

    article {
      width: 100%;
    }

    article:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`
export const ArticleContent = styled.div`
  padding-bottom: 60px;
`

export const ContactText = styled.p`
  font-weight: 700;
`

export const Markdown = styled(ReactMarkdown)`
  ${markdownStyling}
`

export const ImageRenderer = styled(LazyLoadImage)`
  max-width: 100%;

  ${({ isMobile }) => isMobile && "width: 100%"};
`

export const ImageRendererNoGap = styled(LazyLoadImage)`
  display: block;
  max-width: 100%;

  ${({ isMobile }) => isMobile && "width: 100%"};
`

export const DownloadableLogoContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  justify-content: flex-start;

  margin: 50px 0;
`
export const DownloadText = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding-bottom: 20px;

  display: flex;
  flex-wrap: wrap;
  align-items: ${({ isMobile }) => (isMobile ? "end" : "center")};

  background: ${({ isMobile }) => (isMobile ? "none" : "rgba(255, 255, 255, 0.7);")};
  border: ${({ isMobile }) => (isMobile ? "1px solid rgba(0, 0, 0, 0.4);" : "none")};

  visibility: ${({ isMobile }) => (isMobile ? "visible" : "hidden")};
  opacity: ${({ isMobile }) => (isMobile ? "1" : "0")};
  transition: all 0.3s ease-in-out;

  span {
    font-weight: bold;
    font-size: 0.9em;
    text-transform: uppercase;

    background: #000;
    color: #fff;

    margin: 0 auto;
    padding: 10px;
  }
`

export const DownloadableLogo = styled.div`
  position: relative;
  margin: 30px;
  padding: 25px;

  width: 250px;
  height: 150px;

  display: flex;
  align-items: center;

  cursor: pointer;

  img {
    max-width: 100%;
  }

  :hover {
    ${DownloadText} {
      visibility: visible;
      opacity: 1;

      transition: all 0.3s ease-in-out;
    }
  }
`
