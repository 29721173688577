import styled from "styled-components"

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 850px;
`

export const About = styled.div`
  display: flex;
  justify-content: flex-start;

  width: 100%;

  background-color: ${props => props.bgColor};

  @media (max-width: ${props => props.theme.sizes.about.mobile}px) {
    flex-direction: column;

    ${TextContainer} {
      margin-bottom: 40px;
      text-align: center;
    }
  }

  @media (min-width: ${props => props.theme.sizes.about.mobile}px) and (max-width: 1200px) {
    ${TextContainer} {
      margin-right: 40px;
    }
  }
`

export const Text = styled.div`
  position: relative;

  z-index: 2;

  font-family: ${props => props.theme.fonts.primary};

  h2,
  h3 {
    margin-bottom: 25px;

    font-size: 1.3125em;
    font-weight: 700;
  }
  p {
    font-size: 1.125em;
    line-height: 24px;
  }
`
