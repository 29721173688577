import { Link } from "react-router-dom"
import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

export const HomeSection = styled.section.attrs(props => ({
  backgroundColor: props.bgColor || "#fff"
}))`
  position: relative;

  /* overflow: hidden; */

  padding: 110px 0;

  background-color: ${props => props.backgroundColor};
`

export const ChildrenContainer = styled.div`
  ${constrictedContainer};

  z-index: 2;
`

export const OutboundLink = styled(Link).attrs(props => ({
  color: props.outboundlinkcolor || "#000"
}))`
  position: absolute;
  bottom: 45px;
  left: 0;
  right: 0;

  display: block;

  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.3125em;
  font-weight: 700;
  color: ${props => props.color};
  text-decoration: none;
  text-align: center;

  :hover {
    text-decoration: underline;
  }
`
