import PropTypes from "prop-types"
import React from "react"
import { Link, useHistory } from "react-router-dom"

import langEng from "../../assets/images/languages/english.svg"
import langIta from "../../assets/images/languages/italian.svg"
import langJap from "../../assets/images/languages/japanese.svg"
import { useScreenSize } from "../../utils/hooks"
import LoginButton from "../LoginButton"
import Menu from "../Menu"
import RegisterButton from "../RegisterButton"
import * as S from "./style"

const languagesArray = [
  { image: langEng, language: "English", langCode: "en" },
  { image: langIta, language: "Italian", langCode: "it" },
  { image: langJap, language: "Japanese", langCode: "jp" }
]

const NavBar = ({
  userLanguage,
  handleLanguageChange,
  navLinks,
  translations,
  registerOptions,
  brandLoginOptions,
  handleBodyScrollLockChange,
  logo,
  siteLang
}) => {
  const screenSize = useScreenSize()

  const [isMenuVisible, setIsMenuVisible] = React.useState(false)
  const [historyLocPath, setHistoryLocPath] = React.useState(useHistory().location.pathname)

  const languages = languagesArray.reduce((sum, item) => (siteLang.includes(item.langCode) ? sum.concat(item) : sum), [])

  const handleMenuCloseButtonClick = () => {
    handleBodyScrollLockChange(false)
    setIsMenuVisible(false)
  }

  const handleMenuButtonClick = () => {
    if (isMenuVisible) {
      handleMenuCloseButtonClick()
    } else {
      setIsMenuVisible(true)
      if (screenSize.width.lessThanOrEqualTo(768)) handleBodyScrollLockChange(true)
    }
  }

  const checkForPathMatch = link => link === historyLocPath

  return (
    <S.NavBar>
      <S.NavBarInner>
        <Link to="/">
          <S.Logo src={logo} alt="Logo" />
        </Link>
        <S.FlexSpacer />
        {screenSize.width.greaterThanOrEqualTo(768) &&
          navLinks.map(link => (
            <S.WebLink
              key={link.text}
              to={link.to}
              smooth={link.smoothScroll}
              onClick={() => setHistoryLocPath(link.to)}
              {...(link.matchPath && { $isSelected: checkForPathMatch(link.matchPath) })}
            >
              {link.text}
            </S.WebLink>
          ))}
        {screenSize.width.greaterThanOrEqualTo(768) && (
          <RegisterButton options={registerOptions} label={translations.register} to={`/${translations.register.toLowerCase()}`} />
        )}
        <LoginButton options={brandLoginOptions} label={translations.login} />

        {(languages.length > 1 || screenSize.width.lessThan(768)) && (
          <>
            <S.MenuButton isOpen={isMenuVisible} onClick={handleMenuButtonClick} />
            <Menu
              navLinks={navLinks}
              checkFunction={checkForPathMatch}
              isVisible={isMenuVisible}
              handleClose={handleMenuCloseButtonClick}
              handleLanguageChange={handleLanguageChange}
              languages={languages}
              userLanguage={userLanguage}
            />
          </>
        )}
      </S.NavBarInner>
    </S.NavBar>
  )
}

NavBar.propTypes = {
  userLanguage: PropTypes.string,
  handleLanguageChange: PropTypes.func.isRequired,
  navLinks: PropTypes.arrayOf(PropTypes.any),
  translations: PropTypes.objectOf(PropTypes.any),
  registerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      loginUrl: PropTypes.string
    })
  ).isRequired,
  brandLoginOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      loginUrl: PropTypes.string
    })
  ).isRequired,
  handleBodyScrollLockChange: PropTypes.func.isRequired,
  logo: PropTypes.string,
  siteLang: PropTypes.arrayOf(PropTypes.any).isRequired
}

NavBar.defaultProps = {
  userLanguage: "english",
  navLinks: [],
  translations: {},
  logo: null
}

export default NavBar
