import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

// export const Header = styled.section`
//   display: flex;
//   justify-content: center;

//   height: 796px;
//   margin-top: 60px;

//   background-color: #bcbcbc;
//   background-image: url(${props => props.imgurl});
//   background-repeat: no-repeat;
//   background-position: 50%;

//   @media (min-width: 1921px) {
//     background-size: 100vw auto;
//   }
// `;

export const Hero = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  flex: none;

  overflow: hidden;

  min-height: 796px;

  margin-top: 60px;

  background-color: #bcbcbc;

  @media (max-width: 500px) {
    min-height: calc(100vh - 60px);
  }

  @media (min-width: 1921px) {
    background-size: 100vw auto;
  }
`

export const HeroImage = styled(LazyLoadImage)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

export const HeroVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;

  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;

  transform: translate(-50%, -50%);
`

export const HeroInner = styled.div`
  ${constrictedContainer}
  position: relative;

  margin: auto;

  @media (max-width: ${props => props.theme.sizes.header.mobile}px) {
    padding-left: 0;
  }
`

export const HeroContainer = styled.div`
  box-sizing: border-box;

  max-width: 608px;

  padding: 40px;
  margin-bottom: 25px;

  background-color: rgba(0, 0, 0, 0.5);

  font-family: ${props => props.theme.fonts.primary};
`

export const HeroTitle = styled.h1`
  margin-bottom: 8px;

  color: ${props => props.theme.colours.title};
  font-size: 3.375em;
  font-weight: 700;

  @media (max-width: ${props => props.theme.sizes.header.mobile}px) {
    font-size: 2.375em;
  }
`

export const HeroDescription = styled.h4`
  max-width: 500px;

  color: ${props => props.theme.colours.title};
  font-size: 1.875em;
  font-weight: 600;
  line-height: 40px;
`
