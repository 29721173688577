import PropTypes from "prop-types"
import React from "react"

import { resizeContentfulImage, setQualityContentfulImage } from "../../helpers"
import * as S from "./style"

const createLink = slug => `/news/${slug}`

const NewsGridItem = ({ data: { category, newsTitle, slug, newsThumbnail }, size, translations, isSwiperItem }) => (
  <S.NewsGridItem size={size}>
    <S.ArticleLink to={createLink(slug)}>
      {isSwiperItem ? (
        <>
          <S.SwiperImage
            loading="lazy"
            width="297"
            height="297"
            alt="Article"
            className="swiper-lazy"
            data-src={resizeContentfulImage(newsThumbnail, 297, 297, 75)}
          />
          <div className="swiper-lazy-preloader" />
        </>
      ) : (
        <S.Image alt="Article" src={setQualityContentfulImage(newsThumbnail, 75)} />
      )}
      {category.map((item, i) => item.categoryType === "brands" && <S.Brand key={i}>{item.categoryName}</S.Brand>)}
      <S.ArticleContainer>
        <S.ArticleTitle>
          <h3>{newsTitle}</h3>
        </S.ArticleTitle>
        <S.ReadMore>{translations.readMore}</S.ReadMore>
      </S.ArticleContainer>
    </S.ArticleLink>
  </S.NewsGridItem>
)

NewsGridItem.propTypes = {
  data: PropTypes.shape({
    newsDate: PropTypes.string,
    category: PropTypes.arrayOf(PropTypes.any),
    newsTitle: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    newsThumbnail: PropTypes.string,
    newsImage: PropTypes.string
  }).isRequired,
  size: PropTypes.string,
  translations: PropTypes.objectOf(PropTypes.any),
  isSwiperItem: PropTypes.bool
}

NewsGridItem.defaultProps = {
  size: "",
  translations: {},
  isSwiperItem: false
}

export default NewsGridItem
