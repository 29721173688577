import PropTypes from "prop-types"
import React from "react"
import ReactPaginate from "react-paginate"

import * as S from "./style"

const PaginationControl = ({ totalPages, currentPage, onPageChange }) => (
  <S.Pagination>
    <ReactPaginate
      pageRangeDisplayed={5}
      marginPagesDisplayed={1}
      pageCount={totalPages}
      initialPage={currentPage}
      onPageChange={onPageChange}
      disableInitialCallback
      containerClassName="container"
      pageClassName="page"
      previousClassName="previous-button"
      previousLabel=""
      nextClassName="next-button"
      nextLabel=""
    />
  </S.Pagination>
)

PaginationControl.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
}

export default PaginationControl
