import PropTypes from "prop-types"
import React from "react"

import NewsGridItem from "../NewsGridItem"
import * as S from "./style"

const NewsGrid = ({ articles, translations }) => (
  <S.GridArticles>
    {articles.map((article, ind) => (
      <NewsGridItem key={ind} data={article} size="auto" translations={translations} />
    ))}
  </S.GridArticles>
)

NewsGrid.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  translations: PropTypes.objectOf(PropTypes.any)
}

NewsGrid.defaultProps = {
  translations: {}
}

export default NewsGrid
