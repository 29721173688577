import ReactMarkdown from "react-markdown"
import styled from "styled-components"

const maxWidth = "850px"

export const CosmeticTop = styled.div`
  height: 40px;

  margin-top: 60px;

  background: ${props => props.colour};
`

export const MarketContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px 0;

  background: ${props => props.backgroundColor};
`

export const MarketTitle = styled.div`
  padding-bottom: 20px;

  font-size: 3em;
  color: ${props => props.textColor};
`

export const Breadcrumbs = styled.div`
  display: flex;
  padding-bottom: 20px;
  color: ${props => props.textColor};

  span {
    padding-right: 5px;
  }

  a {
    color: ${props => props.textColor};
    text-underline-offset: 2px;
  }
`

export const About = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  padding: 20px 0;
`

export const AboutInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${maxWidth};
  width: 100%;

  padding: 0 15px;
`

export const Description = styled(ReactMarkdown)`
  max-width: ${maxWidth};
  flex-direction: column;

  padding: 0 15px 50px;

  font-size: 1.1em;
  color: ${props => props.textColor};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  background-color: #fff;
  justify-content: center;
  width: 100%;

  padding-top: 50px;

  line-height: 1.3;
  color: #000;

  @media (max-width: 900px) {
    padding: 20px 0;
  }
  p {
    padding-bottom: 50px;
    font-size: 1.1em;
  }

  h4 {
    font-size: 1.3em;
    text-transform: uppercase;

    padding-bottom: 10px;
    letter-spacing: 0.1em;
  }
`

export const ContentInner = styled(ReactMarkdown)`
  display: flex;
  flex-direction: column;

  max-width: ${maxWidth};
  width: 100%;

  p {
    padding-bottom: 50px;
    font-size: 1.1em;
  }

  h4 {
    font-size: 1.3em;
    text-transform: uppercase;

    padding-bottom: 10px;
    letter-spacing: 0.1em;
  }

  ul {
    li {
      margin-left: 16px;

      list-style: disc;

      :last-child {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 0 15px;
    width: auto;
  }
`
