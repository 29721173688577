import { LazyLoadImage } from "react-lazy-load-image-component"
import ReactMarkdown from "react-markdown"
import styled, { css } from "styled-components"

import { smallConstrictedContainer, markdownStyling } from "../../styles/globalStyle"

export const Breadcrumbs = styled.div`
  display: flex;
  width: 100%;

  color: ${props => props.textColor};

  span {
    padding-right: 5px;
  }

  a {
    color: ${props => props.textColor};
    text-underline-offset: 2px;
  }
`

export const CosmeticTop = styled.div`
  height: 40px;

  margin-top: 60px;

  background: ${props => props.colour};
`

export const BrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;

  padding: 70px 0;

  background: ${props => props.colour};

  @media (max-width: 900px) {
    padding: 40px 0;
  }
`

export const About = styled.div`
  ${smallConstrictedContainer};

  display: flex;
  justify-content: center;

  width: 100%;
`

export const AboutInner = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  width: 50%;

  @media (max-width: 900px) {
    align-items: center;

    width: 80%;
  }

  @media (max-width: 550px) {
    width: 98%;
  }
`

export const Logo = styled(LazyLoadImage)`
  align-self: flex-start;
  max-height: 150px;
  //max-width: 100%;
  max-width: 280px;

  padding: 20px 0 35px;
`

const brandMarkdownStyling = css`
  ${markdownStyling};

  margin: 0;

  color: #fff;
  font-family: ${props => props.theme.fonts.openSans};

  border: none;
`

export const Description = styled(ReactMarkdown)`
  ${brandMarkdownStyling};

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 30px;

  color: ${props => props.textColour};
`

export const MarketsContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  a {
    text-decoration: none;
  }
`

export const MarketsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0;
  width: 100%;
  max-width: 100%;
  gap: 12px 0;
`

export const Market = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
  }
  display: ${props => (props.visible ? "flex" : props.index < 5 ? "flex" : "none")};

  span {
    color: ${props => props.textColor};
    padding-top: 10px;
    font-size: 0.8em;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  @media (max-width: 900px) {
    display: ${props => (props.visible ? "flex" : props.index < 3 ? "flex" : "none")};
  }

  :hover {
    transform: scale(1.05);
    transition: transform 0.15s ease-in-out;
  }
`

export const CollapseButton = styled.div`
  display: ${props => (props.nbMarkets > 5 ? "flex" : "none")};
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  margin: 0 10px;

  border-radius: 50%;
  background: #fff;
  cursor: pointer;

  box-shadow: 2px 2px 2px rgb(0, 0, 0, 0.3);

  span {
    width: 100%;
    height: 100%;

    background-image: url(${props => props.buttonIcon});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 28px;
  }

  :hover {
    transform: scale(1.05);
    transition: transform 0.15s ease-in-out;
  }

  @media (max-width: 900px) {
    display: ${props => (props.nbMarkets > 3 ? "flex" : "none")};
  }
`

export const FlagImage = styled(LazyLoadImage)`
  max-width: 40px;
  border-radius: 50%;
  box-shadow: 2px 2px 2px rgb(0, 0, 0, 0.3);
`

export const MarketTitle = styled.div`
  color: ${props => props.textColor};
  text-transform: uppercase;
  font-weight: bold;

  letter-spacing: 0.1em;
`

export const AbsoluteImage = styled(LazyLoadImage)`
  position: absolute;
  top: 20px;
  left: 50%;

  transform: translateX(-50%);

  width: 65%;
  height: auto;
`

export const Image = styled(LazyLoadImage)`
  width: 90%;

  @media (min-width: 600px) and (max-width: 900px) {
    width: 65%;
  }
`

export const FeatureStrip = styled.div`
  width: 100%;

  margin: 40px 0;

  background-color: ${props => props.colour};
`

export const FeatureStripInner = styled.div`
  ${smallConstrictedContainer};

  display: flex;
  align-items: center;

  height: 100%;

  margin: 10px auto;

  @media (max-width: 900px) {
    justify-content: center;
  }
`

export const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;

  width: 128px;
  height: 100%;

  padding: 5px;

  img {
    max-height: 50%;
    max-width: 50%;
  }

  p {
    margin-top: 20px;

    font-family: ${props => props.theme.fonts.openSans};
    font-size: 0.8em;
    color: ${props => props.textColour};
    text-transform: uppercase;
    text-align: center;
  }

  :not(:last-child) {
    margin-right: 48px;
  }
`

export const ButtonContainer = styled.div`
  ${smallConstrictedContainer};

  @media (max-width: 900px) {
    display: flex;
    justify-content: center;

    width: 80%;
  }
`

export const pixelBetCta = `
  border-radius: 32px;

  color: #fff;
`

export const leoVegasCta = `
  border-radius: 5px;

  color: #fd5c3c;
`

export const goGoCasinoCta = `
  border-radius: 32px;

  border: 6px solid #f446b2;

  color: #fff;
`

export const liveCasinoCta = `
  position: relative;

  ::before {
    content: "";

    display: block;

    position: absolute;

    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;

    border: 2px solid #000;
  }

  color: #000;
  letter-spacing: 0.2em;
  font-weight: 100;
  font-size: 1.1em;
  text-transform: uppercase;
`

export const royalPandaCta = `
  border-radius: 4px;

  border: 2px solid #ed2024;

  color: #fff;
`

export const pinkCasinoCta = `
  border-radius: 32px;

  color: #fff;
  text-transform: uppercase;
`

export const betUkCta = `
  border-radius: 32px;
  border: 1px solid #E61529;

  color: #fff;
  text-transform: uppercase;
`

export const slotBossCta = `
  border-radius: 32px;

  color: #fff;
  text-transform: uppercase;
`

export const twentyOneCta = `
  border-radius: 5px;

  color: #000;

  letter-spacing: 0.2em;
`

export const expektCta = `
  border-radius: 32px;

  color: #fff;
`

export const castleJackpotCta = `
  border-radius: 5px;

  background-color: unset;
  background: linear-gradient(0deg, rgba(8, 115, 59, 1) 0%, rgba(14, 161, 73, 1) 100%);

  color: #fff;
  font-size: 1.1em;
  text-transform: uppercase;

  @media (max-width: 900px) {
    padding: 15px;
  }
`

export const jackpotGuruCta = `
  border-radius: 9px;

  color: #fff;
  text-transform: uppercase;
  font-weight: 100;

  @media (max-width: 900px) {
    padding: 15px;
  }
`

export const casibeeCta = `
  border-radius: 5px;

  color: #000;
  text-transform: uppercase;
  font-weight: 100;

  @media (max-width: 900px) {
    padding: 15px;
  }
`

export const brandButtons = {
  leovegas: leoVegasCta,
  pixelbet: pixelBetCta,
  gogocasino: goGoCasinoCta,
  livecasino: liveCasinoCta,
  royalpanda: royalPandaCta,
  pinkcasino: pinkCasinoCta,
  twentyone: twentyOneCta,
  expekt: expektCta,
  castlejackpot: castleJackpotCta,
  betuk: betUkCta,
  slotboss: slotBossCta,
  jackpotguru: jackpotGuruCta,
  casibee: casibeeCta
}

export const CtaButton = styled.button`
  padding: 15px 48px;
  margin-top: 40px;

  background-color: ${props => props.colour};

  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.5em;
  font-weight: 700;

  border: none;

  cursor: pointer;

  @media (max-width: 900px) {
    width: 80%;
    padding: 15px 30px;
  }

  ${props => brandButtons[props.brand]};
`

export const CosmeticBottom = styled.div`
  height: 150px;

  background: ${props => props.colour};
`
