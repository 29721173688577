import PropTypes from "prop-types"
import React, { useState } from "react"

import iconDown from "../../assets/icons/icon-chevrondown-white.svg"
import iconUp from "../../assets/icons/icon-chevronup-white.svg"
import { pushToDataLayer } from "../../helpers/gtmManager"
import * as S from "./style"

const RegisterButton = ({ options, label, size, to }) => {
  const [areOptionsVisible, setAreOptionsVisible] = useState(false)

  const handleDropdownClick = () => setAreOptionsVisible(!areOptionsVisible)
  const handleMouseLeave = () => setAreOptionsVisible(false)

  return (
    <>
      {options.length > 1 ? (
        <S.RegisterButton onMouseLeave={handleMouseLeave} size={size} icon={areOptionsVisible ? iconUp : iconDown} onClick={handleDropdownClick}>
          {label}
          <S.Options isVisible={areOptionsVisible}>
            {options.map((opt, ind) => {
              const gtmData = { action: "register", label: opt.brand }
              return (
                <S.Option key={ind} href={`${to}/${opt.brand}`} url={opt.url} onClick={() => gtmData && pushToDataLayer(gtmData)}>
                  <p>{opt.brand}</p>
                </S.Option>
              )
            })}
          </S.Options>
        </S.RegisterButton>
      ) : (
        <S.RegisterButton to={to} url={options[0].url} gtmData={{ action: "register", label: options[0].brand }}>
          {label}
        </S.RegisterButton>
      )}
    </>
  )
}

RegisterButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  to: PropTypes.string.isRequired
}

RegisterButton.defaultProps = {
  size: "small"
}

export default RegisterButton
