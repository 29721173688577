import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { fadeInOut } from "../../styles/animation"

export const ImageContainer = styled.div`
  position: relative;

  display: flex;
  align-items: flex-end;
  flex: 0 0 auto;

  height: 100%;
  width: 180px;

  a {
    text-decoration: none;
  }
`

export const Image = styled(LazyLoadImage)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
`

export const Brands = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  height: 100%;
`

export const BrandTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Brand = styled.h3`
  position: relative;

  padding: 8px;
  margin-bottom: 4px;

  background-color: ${props => props.theme.news.previewInfoBg};

  color: #fff;

  font-family: ${props => props.theme.fonts.primary};
  font-size: 1em;

  :not(:last-child) {
    margin-right: 4px;
  }
`

export const Title = styled.h3`
  font-size: 1.3125em;
  font-weight: 700;

  margin-bottom: 10px;
`

export const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  overflow-y: hidden;

  max-height: 100%;

  padding: 0 8px;

  font-family: ${props => props.theme.fonts.primary};

  p {
    font-size: 1em;

    margin-bottom: 10px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  a {
    color: ${props => props.theme.colours.main};

    margin-bottom: 10px;

    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`
export const ArticlePreview = styled.div`
  a {
    color: #000;
    text-decoration: none;

    :hover {
      text-decoration: none;
    }
  }
`

export const ArticleLink = styled(Link)`
  color: #000;
  text-decoration: none;

  :not(:last-child) {
    margin-bottom: 20px;
  }
`

export const ReadMore = styled.p`
  color: ${props => props.theme.colours.main};
`

export const NewsListItem = styled.article`
  display: flex;

  height: 180px;

  animation: ${fadeInOut} 0.25s ease-in forwards;

  @media (max-width: 550px) {
    height: 130px;

    ${ImageContainer} {
      width: 130px;
    }

    ${Title} {
      font-size: 1.1em;
    }

    ${Brand} {
      font-size: 0.85em;
    }
  }
`
