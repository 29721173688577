import * as PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import breaks from "remark-breaks"

import { useScrollTo } from "../../helpers"
import { brandColorData } from "../../styles/brandsColor"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const MarketPage = ({ market: { countryCode, brandId, marketTitle, seoTitle, seoDescription, description, content }, brandName, translations }) => {
  useScrollTo()

  const colours = brandColorData[brandId]

  return (
    <PageTemplate>
      <Helmet>
        <title data-react-helmet="true">{seoTitle}</title>
        <meta name="description" content={seoDescription} data-react-helmet="true" />
      </Helmet>

      <S.CosmeticTop colour={colours.main} />

      <S.MarketContainer backgroundColor={colours.background}>
        <S.About>
          <S.AboutInner>
            <S.Breadcrumbs textColor={colours.text}>
              <span>
                <Link to={`/${translations.brands}`}>{translations.brands}</Link>
              </span>
              <span>{`>`}</span>
              <span>
                <Link to={`/${translations.brands}/${brandId}`}>{brandName}</Link>
              </span>
              <span>{`>`}</span>
              <span>{countryCode}</span>
            </S.Breadcrumbs>
            <S.MarketTitle textColor={colours.text}>{marketTitle}</S.MarketTitle>
          </S.AboutInner>
        </S.About>
        <S.Content>
          {description && <S.Description plugins={[breaks]}>{description}</S.Description>}
          <S.ContentInner plugins={[breaks]}>{content}</S.ContentInner>
        </S.Content>
      </S.MarketContainer>
    </PageTemplate>
  )
}

MarketPage.propTypes = {
  market: PropTypes.objectOf(PropTypes.any),
  countryCode: PropTypes.string,
  brandId: PropTypes.string,
  marketTitle: PropTypes.string,
  brandName: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  translations: PropTypes.any
}

MarketPage.defaultProps = {
  market: {},
  countryCode: null,
  brandId: null,
  marketTitle: null,
  brandName: null,
  seoTitle: null,
  seoDescription: null,
  description: null,
  content: null,
  translations: {}
}

export default MarketPage
